import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import PungoDemoAccordion from "../../../../pungo-ui/PungoDemoAccordion";
import PungoTable from "pungo-ui/PungoTable";
import { selectProResults } from "store/slices/plan";

import styles from "./index.module.scss";
import "../../../../styles/variables.scss";

const DataInputProSection: React.FC = () => {
  const { t } = useTranslation();

  const proPlanResults = useSelector(selectProResults);

  const labelsForProductionWellTable = [
    t("proPlan.tableLabels.wellProduction.well"),
    t("proPlan.tableLabels.wellProduction.bbpd"),
    t("proPlan.tableLabels.wellProduction.bwpd"),
    t("proPlan.tableLabels.wellProduction.mscfd"),
    t("proPlan.tableLabels.wellProduction.headPressure"),
    t("proPlan.tableLabels.wellProduction.headTemperature"),
    t("proPlan.tableLabels.wellProduction.bottomPressure"),
    t("proPlan.tableLabels.wellProduction.bottomTemperature"),
    t("proPlan.tableLabels.wellProduction.diameter"),
    t("proPlan.tableLabels.wellProduction.depth"),
  ];

  const labelsForLaboratoryAnalysisTable = [
    t("proPlan.tableLabels.laboratoryAnalysis.well"),
    t("proPlan.tableLabels.laboratoryAnalysis.chlorides"),
    t("proPlan.tableLabels.laboratoryAnalysis.CO2"),
    t("proPlan.tableLabels.laboratoryAnalysis.alkalinity"),
    t("proPlan.tableLabels.laboratoryAnalysis.sodium"),
    t("proPlan.tableLabels.laboratoryAnalysis.potasium"),
    t("proPlan.tableLabels.laboratoryAnalysis.magnesium"),
    t("proPlan.tableLabels.laboratoryAnalysis.calcium"),
    t("proPlan.tableLabels.laboratoryAnalysis.strontium"),
    t("proPlan.tableLabels.laboratoryAnalysis.barium"),
    t("proPlan.tableLabels.laboratoryAnalysis.sulfates"),
    t("proPlan.tableLabels.laboratoryAnalysis.carboxylicAcids"),
  ];

  const labelsForChemicalInjectionTable = [
    t("proPlan.tableLabels.chemicalInyection.well"),
    t("proPlan.tableLabels.chemicalInyection.anticorrosiveDosage"),
    t("proPlan.tableLabels.chemicalInyection.antiscaleDosage"),
    t("proPlan.tableLabels.chemicalInyection.anticorrosivePrice"),
    t("proPlan.tableLabels.chemicalInyection.antiscalePrice"),
  ];

  const getWellProduction = () => (
    <PungoTable
      values={proPlanResults?.map(({ wellName, wellProduction }) => {
        return [
          wellName,
          wellProduction.bopd,
          wellProduction.bwpd,
          wellProduction.mscfd,
          wellProduction.pressureHead,
          wellProduction.temperatureHead,
          wellProduction.pressureBottom,
          wellProduction.temperatureBottom,
          wellProduction.pipeDiameter,
          wellProduction.pipeDepth,
        ];
      })}
      labels={labelsForProductionWellTable}
      height={300}
    />
  );

  const getLaboratoryAnalysis = () => (
    <PungoTable
      values={proPlanResults?.map(({ wellName, laboratoryAnalysis }) => {
        return [
          wellName,
          laboratoryAnalysis.chlorine,
          laboratoryAnalysis.co2Fraction,
          laboratoryAnalysis.alkalinity,
          laboratoryAnalysis.sodium,
          laboratoryAnalysis.potassium,
          laboratoryAnalysis.magnesium,
          laboratoryAnalysis.calcium,
          laboratoryAnalysis.strontium,
          laboratoryAnalysis.barium,
          laboratoryAnalysis.sulfate,
          laboratoryAnalysis.carboxylicAcid,
        ];
      })}
      labels={labelsForLaboratoryAnalysisTable}
      height={300}
    />
  );

  const getChemicalInjection = () => (
    <div className={styles.chemicalInyectionContainer}>
      <div className={styles.note}>
        <Trans>{t("proPlan.labels.chemicalNote")}</Trans>
      </div>
      <PungoTable
        values={proPlanResults?.map(({ wellName, chemicalsInjection }) => {
          return [
            wellName,
            chemicalsInjection.anticorrosiveDose,
            chemicalsInjection.antiscaleDose,
            chemicalsInjection.anticorrosivePrice,
            chemicalsInjection.antiscalePrice,
          ];
        })}
        labels={labelsForChemicalInjectionTable}
        height={300}
      />
    </div>
  );

  return (
    <PungoSectionCard title={t("proPlan.section.dataInput")}>
      <div className={styles.container}>
        <div className={styles.accordionContainer}>
          <PungoDemoAccordion
            title={t("proPlan.labels.wellProduction")}
            content={getWellProduction()}
          />
        </div>
        <div className={styles.accordionContainer}>
          <PungoDemoAccordion
            title={t("proPlan.labels.laboratoryAnalysis")}
            content={getLaboratoryAnalysis()}
          />
        </div>
        <div className={styles.accordionContainer}>
          <PungoDemoAccordion
            title={t("proPlan.labels.chemicalsInjection")}
            content={getChemicalInjection()}
          />
        </div>
      </div>
    </PungoSectionCard>
  );
};

export default DataInputProSection;
