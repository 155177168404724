import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Download, UploadFile } from "@mui/icons-material";
import { useAppDispatch } from "app/hooks";

import { selectSelectedPlan } from "store/slices/plan";
import {
  downloadCalculationTemplate,
  uploadCalculationTemplate,
} from "store/actions/planActions";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import { selectUserRole } from "store/slices/auth";
import PungoModal from "pungo-ui/PungoModal";
import PungoButton from "pungo-ui/PungoButton";
import PlanModalContainer from "components/PlanModalContainer";

import styles from "./index.module.scss";
import ProBadge from "components/common/ProBadge";

const InstructionsSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedPlan = useSelector(selectSelectedPlan);
  const userRoles = useSelector(selectUserRole);
  const isFreeUser = userRoles?.includes("free_plan");

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [showNotAllowedFileModal, setShowNotAllowedFileModal] = useState(false);

  const getInstructionsContent = () => (
    <div className={styles.text}>
      <Trans>{t("DemoPage.InstructionsSection.steps")}</Trans>
      <div className={styles.note}>
        <Trans>{t("DemoPage.InstructionsSection.note")}</Trans>
      </div>
    </div>
  );

  const clearFileInput = (ctrl: any) => {
    try {
      ctrl.value = null;
    } catch (ex) {}
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  };

  const handleOnCloseModal = () => {
    setShowNotAllowedFileModal(false);
    clearFileInput(document.getElementById("file"));
    setUploadedFile(null);
  };

  useEffect(() => {
    if (uploadedFile) {
      const extension = uploadedFile.name.substring(
        uploadedFile.name.lastIndexOf(".") + 1
      );
      if (extension !== "xlsx") {
        setShowNotAllowedFileModal(true);
      }
    }
  }, [uploadedFile]);

  const onUploadFile = () => {
    if (uploadedFile) {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("filename", uploadedFile.name);
      dispatch(uploadCalculationTemplate(formData));
      setUploadedFile(null);
      clearFileInput(document.getElementById("file"));
    }
  };

  const getProInstructionsContent = () => (
    <div className={styles.text}>
      <Trans>{t("DemoPage.InstructionsSection.proSteps")}</Trans>
      {isFreeUser && (
        <div className={styles.note}>
          <Trans>{t("DemoPage.InstructionsSection.proResultsNote")}</Trans>
        </div>
      )}
      <div className={styles.buttonSection}>
        <ProBadge />
        <PungoButton
          label={`${t("manageProfile.downloadFileButton")}`}
          type="text"
          onClick={() => dispatch(downloadCalculationTemplate())}
          color="secondary"
          icon={<Download />}
          disabled={isFreeUser}
        />
        <input
          type="file"
          id="file"
          onChange={(event: any) => setUploadedFile(event.target.files[0])}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          className={styles.fileInput}
          disabled={isFreeUser}
        />
        <PungoButton
          label={`${t("manageProfile.uploadFileButton")}`}
          type="text"
          onClick={onUploadFile}
          color="secondary"
          icon={<UploadFile />}
          disabled={isFreeUser || !uploadedFile}
        />
      </div>
    </div>
  );

  return (
    <PungoSectionCard title={t("DemoPage.InstructionsSection.instructions")}>
      <div className={styles.container}>
        {selectedPlan === "free"
          ? getInstructionsContent()
          : getProInstructionsContent()}
      </div>
      <PungoModal
        open={showNotAllowedFileModal}
        classNames={styles.modal}
        title={`${t("admin.detailsLabel")}`}
        handleClose={handleOnCloseModal}
        primaryAction={{
          label: "Ok",
          onClick: handleOnCloseModal,
        }}
      >
        <div className={styles.modalChild}>
          {t("manageProfile.fileNotAllowed")}
        </div>
      </PungoModal>
      <PlanModalContainer/>
    </PungoSectionCard>
  );
};

export default InstructionsSection;
