import React from "react";
import { useTranslation } from "react-i18next";

import monitor from "../../../../assets/PungoIcons/MonitorAnimation.svg";

import styles from "./index.module.scss";

const AnimationSection: React.FC = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  return (
    <section className={styles.container} id="start">
      <div className={styles.text}>
        {language === "es" ? (
          <p className={styles.header}>
            Plataforma digital para la gestión del&nbsp;
            <span className={styles.accents}>tratamiento químico</span> en la
            industria petrolera.
          </p>
        ) : (
          <p className={styles.header}>
            Digital platform for the management of&nbsp;
            <span className={styles.accents}>chemical treatment</span>
            &nbsp; in the oil industry
          </p>
        )}
      </div>
      <img className={styles.gif} src={monitor} alt="Monitor animation" />
    </section>
  );
};

export default AnimationSection;
