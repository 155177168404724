import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AstroDialog from "pungo-ui/AstroDialog";
import { selectProPlanModalResponse } from "store/slices/planModal";

const PlanModalContainer: React.FC = () => {
  const proPlanErrorMessage = useSelector(selectProPlanModalResponse);
  const { t } = useTranslation();

  return <AstroDialog text={t(proPlanErrorMessage)} />;
};

export default PlanModalContainer;
