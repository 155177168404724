import React from "react";
import { useTranslation } from "react-i18next";

import monitor from "../../../../assets/AstroImages/oilMonitor.svg";

import styles from "./index.module.scss";

const AnimationSection: React.FC = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  return (
    <section className={styles.container} id="start">
      <div className={styles.text}>
        {language === "es" ? (
          <p className={styles.header}>
            <b>Bienvenido a ASTRO:</b>&nbsp; La herramienta digital para la
            <span className={styles.accents}> gestión </span>del
            <span className={styles.accents}> tratamiento químico </span>en la
            industria petrolera.
          </p>
        ) : (
          <p className={styles.header}>
           <b>Welcome to ASTRO:</b>&nbsp; The digital tool for the <span className={styles.accents}>management </span>
            of <span className={styles.accents}>chemical treatment </span>
            in the oil industry.
          </p>
        )}
      </div>
      <img className={styles.gif} src={monitor} alt="Monitor animation" />
    </section>
  );
};

export default AnimationSection;
