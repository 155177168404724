import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import styles from "./index.module.scss";

interface IDemoResultsTable {
  labels: string[];
  values: any;
  height?: number;
  personalizedWidth?: number;
}

const PungoTable: React.FC<IDemoResultsTable> = (props) => {
  const { labels, values, height = 100, personalizedWidth } = props;

  return (
    <TableContainer
      className={styles.tableContainer}
      sx={{ maxHeight: height }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow aria-label="sticky table">
            {labels.map((label) => (
              <TableCell
                key={label}
                className={styles.energySources}
                aria-label="sticky table"
                style={
                  personalizedWidth
                    ? { minWidth: `${personalizedWidth}px` }
                    : undefined
                }
              >
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row: any, index: number) => (
            <TableRow key={index}>
              {row.map((value: any, index: number) => (
                <TableCell
                  className={styles.cell}
                  key={`${value}-${index}`}
                  style={
                    personalizedWidth
                      ? { minWidth: `${personalizedWidth}px` }
                      : undefined
                  }
                >
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PungoTable;
