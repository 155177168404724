import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

interface IPlan {
  proPlanActive: boolean;
  planType: string;
  remainingUsages: number | null;
  endDate: string | null;
  paymentMethodType: string;
  paymentMethodBrand: string | null;
  paymentMethodLastDigits: string | null;
  paymentMethodExpiration: string | null;
}

interface IProfile {
  name: string;
  email: string;
  company: string;
}

const emptyPlan: IPlan = {
  proPlanActive: false,
  planType: "",
  remainingUsages: 0,
  endDate: "",
  paymentMethodType: "",
  paymentMethodBrand: "",
  paymentMethodLastDigits: "",
  paymentMethodExpiration: "",
};

const emptyProfile: IProfile = {
  name: "",
  email: "",
  company: "",
};

const initialState = {
  profile: emptyProfile,
  plan: emptyPlan,
};

const ProfileSlice = createSlice({
  name: "profileData",
  initialState,
  reducers: {
    clearProfileManagement: (state) => {
      state.profile = emptyProfile;
      state.plan = emptyPlan;
    },
    saveProfile: (state, action: PayloadAction<IProfile>) => {
      state.profile = action.payload;
    },
    savePlan: (state, action: PayloadAction<IPlan>) => {
      state.plan = action.payload;
    },
  },
});
export const { savePlan, saveProfile, clearProfileManagement } =
  ProfileSlice.actions;

export default ProfileSlice.reducer;

export const selectPlan = (state: RootState): IPlan =>
  state.profileManagement.plan;

export const selectProfile = (state: RootState): IProfile =>
  state.profileManagement.profile;
