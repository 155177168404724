import { GridColDef } from "@mui/x-data-grid";

export const columnsForUserTable: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    flex: 0.3,
    minWidth: 80,
    filterable: true,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.3,
    minWidth: 100,
    filterable: true,
  },
  {
    field: "role",
    headerName: "Role",
    flex: 0.4,
    minWidth: 90,
    filterable: true,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 0.5,
    minWidth: 130,
    filterable: true,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 220,
    filterable: true,
  },
  {
    field: "companyName",
    headerName: "Company",
    flex: 0.8,
    minWidth: 150,
    filterable: true,
  },
];

export const columnsForEnergiesTable: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    flex: 0.3,
    minWidth: 80,
    filterable: true,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.3,
    minWidth: 100,
    filterable: true,
  },
  {
    field: "mostCommon",
    headerName: "Most Common",
    flex: 1,
    minWidth: 160,
    filterable: true,
  },
  {
    field: "energyType",
    headerName: "Type",
    flex: 1,
    minWidth: 130,
    filterable: true,
  },
  {
    field: "unitCost",
    headerName: "Cost",
    flex: 1,
    minWidth: 90,
    filterable: true,
  },
  {
    field: "name_es",
    headerName: "Nombre",
    flex: 1,
    minWidth: 150,
    filterable: true,
  },
  {
    field: "name_en",
    headerName: "Name",
    flex: 1,
    minWidth: 150,
    filterable: true,
  },
  {
    field: "units_es",
    headerName: "Unidad",
    flex: 1,
    minWidth: 110,
    filterable: true,
  },
  {
    field: "units_en",
    headerName: "Unit",
    flex: 1,
    minWidth: 110,
    filterable: true,
  },
  {
    field: "defaultValue",
    headerName: "Default value",
    flex: 1,
    minWidth: 140,
    filterable: true,
  },
  {
    field: "minValue",
    headerName: "Min value",
    flex: 1,
    minWidth: 130,
    filterable: true,
  },
  {
    field: "maxValue",
    headerName: "Max value",
    flex: 1,
    minWidth: 130,
    filterable: true,
  },
  {
    field: "emissionFactor",
    headerName: "Emission factor",
    flex: 1,
    minWidth: 150,
    filterable: true,
  },
  {
    field: "emissionScope_es",
    headerName: "Alcance",
    flex: 1,
    minWidth: 200,
    filterable: true,
  },
  {
    field: "emissionScope_en",
    headerName: "Scope",
    flex: 1,
    minWidth: 200,
    filterable: true,
  },
];
export const columnsForQuestionsTable: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    minWidth: 70,
    filterable: true,
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 90,
    filterable: true,
  },
  {
    field: "number",
    headerName: "Number",
    minWidth: 110,
    filterable: true,
  },
  {
    field: "type",
    headerName: "Type",
    minWidth: 180,
    filterable: true,
  },
  {
    field: "textEs",
    headerName: "Pregunta",
    flex: 0.3,
    minWidth: 300,
    filterable: true,
  },
  {
    field: "textEn",
    headerName: "Question",
    flex: 0.3,
    minWidth: 300,
    filterable: true,
  },
];
export const columnsForChoicesTable: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    flex: 0.3,
    minWidth: 80,
    filterable: true,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.3,
    minWidth: 100,
    filterable: true,
  },
  {
    field: "number",
    headerName: "Number",
    flex: 0.3,
    minWidth: 120,
    filterable: true,
  },
  {
    field: "score",
    headerName: "Score",
    flex: 0.3,
    minWidth: 120,
    filterable: true,
  },
  {
    field: "textEs",
    headerName: "Respuesta",
    flex: 2,
    minWidth: 200,
    filterable: true,
  },
  {
    field: "textEn",
    headerName: "Answer",
    flex: 2,
    minWidth: 200,
    filterable: true,
  },
];
export const columnsForUsageTable: GridColDef[] = [
  {
    field: "time",
    headerName: "Time",
    flex: 1,
    minWidth: 250,
    filterable: true,
  },
  {
    field: "id",
    headerName: "ID",
    flex: 0.3,
    minWidth: 80,
    filterable: true,
  },
  {
    field: "userId",
    headerName: "User Id",
    flex: 0.3,
    minWidth: 120,
    filterable: true,
  },
  {
    field: "userName",
    headerName: "User's email",
    flex: 1,
    minWidth: 200,
    filterable: true,
  },
  {
    field: "callType",
    headerName: "Call type",
    flex: 0.4,
    minWidth: 120,
    filterable: true,
  },
];
