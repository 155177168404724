import { AppDispatch } from "../index";
import { v4 as uuidv4 } from "uuid";
import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import { displaySnackbarAPISuccess, getSnackbarError } from "./utils";
import moment, { Moment } from "moment";
import { store } from "../../store";
import { clerUserSession, refresh } from "./authActions";
import {
  saveRoles,
  saveUsage,
  saveUserDetailById,
  saveUsageByUserId,
  saveUsers,
  saveUsageById,
} from "../slices/user";
import { addAPIcall, completeAPIcall } from "../slices/api";

const userURL = getBaseUrlForAPI("user");
const apiURL = getBaseUrlForAPI("api");
const appUsageURL = getBaseUrlForAPI("appUsage");

export type IUsageTypes = string | null;
export type IPaymentTypes = string| null;

export interface IManageUser {
  companyName: string;
  email: string;
  emailVerificationCode?: string;
  passwordChangeCode?: string;
  emailVerified: boolean;
  name: string;
  role: string;
  surveyCompleted: boolean;
  username: string;
  password?: string;
  planUsageType: IUsageTypes;
  planRemainingUsages: number;
  planEndDate: string;
  planPaymentType: IPaymentTypes;
}

export const getUsers =
  () =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `getUsers-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${userURL}`)
        .then(({ data }) => {
          dispatch(saveUsers(data));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const getRoles =
  () =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `getRoles-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${apiURL}/core/role`)
        .then(({ data }) => {
          dispatch(saveRoles(data));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const deleteUsers =
  (userId: number) =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `deleteUsers-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .delete(`${userURL}/${userId}`)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.userDeleted"));
          dispatch(getUsers());
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const editUser =
  (userId: number, user: IManageUser) =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `editUser-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .put(`${userURL}/${userId}`, user)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.userEdited"));
          dispatch(getUsers());
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const createUser =
  (user: IManageUser) =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `createUser-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${userURL}`, user)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.userCreated"));
          dispatch(getUsers());
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const fetchUsageResume =
  () =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `fetchUsageResume-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${appUsageURL}/search`)
        .then(({ data }) => {
          dispatch(saveUsage(data));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const fetchUsageByUserId =
  (userId: number) =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `fetchUsageByUserId-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${userURL}/${userId}/app-usages`)
        .then(({ data }) => {
          dispatch(saveUsageByUserId(data));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const fetchUsageById =
  (userId: number) =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `fetchUsageById-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${appUsageURL}/${userId}`)
        .then(({ data }) => {
          dispatch(saveUsageById(data));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const fetchUserDetailsById =
  (userId: number) =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `fetchUserDetailsById-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${userURL}/${userId}`)
        .then(({ data }) => {
          dispatch(saveUserDetailById(data));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };
    
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };
