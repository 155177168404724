import React from "react";
import { Trans, useTranslation } from "react-i18next";

import styles from "./index.module.scss";

interface AstroResultsCardProps {
  id?: string;
  description?: string;
  result?: number | string | React.ReactElement;
  color?: string;
  image?: string;
  reducedCost?: string;
}

const AstroResultsCard: React.FC<AstroResultsCardProps> = (props) => {
  const {
    id,
    description,
    result,
    color,
    image,
    reducedCost,
  } = props;
  const { t } = useTranslation();
  return (
    <div id={id} className={styles.container}>
      {description && (
        <div className={styles.description}>
          <Trans> {description}</Trans>
        </div>
      )}
      <div style={{ background: color }} className={styles.result}>
        {result}
      </div>
      {reducedCost && (
        <div style={{ color: color }} className={styles.reduced}>
          {t("DemoPage.ResultsSection.reducedCost", {
            reducedCost: reducedCost,
          })}
        </div>
      )}
      {image && (
        <div>
          <img className={styles.image} src={image} alt="" />
        </div>
      )}
    </div>
  );
};

export default AstroResultsCard;
