import { useAppDispatch } from "app/hooks";
import PungoButton from "pungo-ui/PungoButton";
import PungoInput from "pungo-ui/PungoInput";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { changePasswordFromSession } from "store/actions/authActions";
import CommonTemplate from "../common/CommonTemplate";

interface ChangePasswordPageProps {}

const ChangePasswordPage: React.FC<ChangePasswordPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [checkedPassword, setCheckedPassword] = useState("");
  const [isNewPasswordSameAsPrevious, setIsNewPasswordSameAsPrevious] =
    useState(false);

  const [isConfirmPasswordDifferent, setIsConfirmPasswordDifferent] =
    useState(false);

  const clearForm = () => {
    setPassword("");
    setNewPassword("");
    setCheckedPassword("");
  };

  const handleOnSubmit = () => {
    dispatch(changePasswordFromSession({ oldPassword: password, newPassword }));
    clearForm();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (newPassword.length) {
      setTimeout(() => {
        if (newPassword === password) {
          setIsNewPasswordSameAsPrevious(true);
        } else {
          setIsNewPasswordSameAsPrevious(false);
        }
      }, 300);
    }
  }, [newPassword, password]);

  useEffect(() => {
    if (checkedPassword.length) {
      setTimeout(() => {
        if (newPassword !== checkedPassword) {
          setIsConfirmPasswordDifferent(true);
        } else {
          setIsConfirmPasswordDifferent(false);
        }
      }, 300);
    }
  }, [newPassword, checkedPassword]);

  return (
    <CommonTemplate title={`${t("changePassword.title")}`} height={400}>
      <PungoInput
        name={`${t("changePassword.actualPassword")}`}
        type="password"
        value={password}
        onChange={setPassword}
      />
      <PungoInput
        name={`${t("changePassword.newPassword")}`}
        type="password"
        value={newPassword}
        onChange={setNewPassword}
        helperText={
          isNewPasswordSameAsPrevious
            ? `${t("changePassword.samePasswordError")}`
            : undefined
        }
      />
      <PungoInput
        name={`${t("changePassword.checkedPassword")}`}
        type="password"
        value={checkedPassword}
        onChange={setCheckedPassword}
        helperText={
          isConfirmPasswordDifferent
            ? `${t("changePassword.newPasswordDontMatch")}`
            : undefined
        }
      />
      <PungoButton
        label={`${t("accountPage.signUp")}`}
        onClick={handleOnSubmit}
        color="secondary"
        disabled={
          !checkedPassword ||
          !password ||
          !newPassword ||
          isConfirmPasswordDifferent ||
          isNewPasswordSameAsPrevious
        }
      />
    </CommonTemplate>
  );
};

export default ChangePasswordPage;
