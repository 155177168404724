import React from "react";

import monitor from "../../../assets/PungoIcons/MonitorAnimation.svg";
import styles from "./index.module.scss";

interface CommonTemplateProps {
  title: string;
  children: React.ReactNode;
  height: number;
}

const CommonTemplate: React.FC<CommonTemplateProps> = (props) => {
  const { title, children, height } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.form} style={{ minHeight: `${height}px` }}>
          <div className={styles.title}>{title}</div>
          {children}
        </div>
        <img className={styles.img} src={monitor} alt="Monitor animation" />
      </div>
    </div>
  );
};

export default CommonTemplate;
