import { useMediaQuery } from "@mui/material";
import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";
import breakpoints from "../../styles/export.module.scss";

import BenefitsRightContainer from "./benefitsRightContainer";
import BenefitsLeftContainer from "./benefitsLeftContainer";
import BenefitsMobileContainer from "./benefitsMobileContainer";

interface PungoBenefitsCardProps {
  image?: string;
  title: string;
  description?: string;
  id?: string;
  color: string;
  imagePosition?: string;
}

const PungoBenefitsCard: React.FC<PungoBenefitsCardProps> = (props) => {
  const { image, title, description, id, color, imagePosition } = props;
  const json2mq = require("json2mq");
  const desktopView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.xlg,
    })
  );
  return (
    <section className={styles.container} id={id}>
      {desktopView ? (
        <div
          className={classNames(styles.content, {
            [styles.rowReverse]: imagePosition === "right",
          })}
        >
          <div className={styles.borderContainer}>
            {imagePosition === "left" ? (
              <BenefitsRightContainer color={color} />
            ) : (
              <BenefitsLeftContainer color={color} />
            )}
          </div>
          <img src={image} alt="" className={styles.image} />
          <div
            className={classNames(styles.textContainer, {
              [styles.rowReverse]: imagePosition === "right",
            })}
          >
            <div className={styles.title} style={{ color: color }}>
              {title}
            </div>
            {description && (
              <div className={styles.description}>{description}</div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.borderContainer}>
            <BenefitsMobileContainer color={color} />
          </div>
          <img src={image} alt="" className={styles.image} />
          <div
            className={classNames(styles.textContainer, {
              [styles.rowReverse]: imagePosition === "right",
            })}
          >
            <div className={styles.title} style={{ color: color }}>
              {title}
            </div>
            {description && (
              <div className={styles.description}>{description}</div>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default PungoBenefitsCard;
