import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

export interface IProPlanModalMessage {
  text: string;
}
const initialState = {
  text: "string",
};
const planModalSlice = createSlice({
  name: "snackbarData",
  initialState,
  reducers: {
    saveProPlanErrorResponse: (
      state,
      action: PayloadAction<IProPlanModalMessage>
    ) => {
      state.text = action.payload.text;
    },
    clearPlanModal: (state) => {
      state.text = "";
    },
  },
});
export const { saveProPlanErrorResponse, clearPlanModal } =
  planModalSlice.actions;

export default planModalSlice.reducer;

export const selectProPlanModalResponse = (state: RootState): string =>
  state.planModalManagement.text;
