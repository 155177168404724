import React, { useState } from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import styles from "./index.module.scss";

interface ISelectableOptions {
  value: number | string;
  label: number | string;
}

interface PungoInputProps {
  name: string;
  helperText?: string | undefined;
  type?: "password" | "number" | "text" | "select" | "date";
  onChange: (value: any) => void;
  onFocus?: (value: any) => void;
  value: string | number;
  options?: ISelectableOptions[];
  disabled?: boolean;
}

const PungoInput: React.FC<PungoInputProps> = (props) => {
  const {
    name,
    helperText,
    type = "text",
    onChange,
    onFocus,
    value,
    options,
    disabled,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const isDateInput = type === "date";

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div className={styles.container}>
      {type !== "select" ? (
        <TextField
          label={name}
          value={value}
          disabled={disabled}
          className={styles.inputContainer}
          inputProps={
            isDateInput
              ? {
                  shrink: true,
                }
              : undefined
          }
          InputLabelProps={
            isDateInput
              ? {
                  shrink: true,
                }
              : undefined
          }
          onKeyDown={isDateInput ? (e) => e.preventDefault() : undefined}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          variant="outlined"
          error={!!helperText}
          helperText={helperText}
          onChange={(event) => onChange(event.target.value)}
          onFocus={onFocus? (event) => onFocus(event) : undefined}
          InputProps={{
            endAdornment: type === "password" && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  className={styles.icon}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <FormControl fullWidth disabled={disabled}>
          <InputLabel>{name}</InputLabel>
          <Select
            label={name}
            value={String(value)}
            onChange={(event: SelectChangeEvent) => {
              onChange(event.target.value as string);
            }}
          >
            {options?.map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default PungoInput;
