import React, { useEffect, useState } from "react";
import { ArrowForwardIos } from "@mui/icons-material";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { selectSurvey } from "store/slices/survey";
import ResultsImage from "../../assets/PungoIcons/Results.svg";
import PungoButton from "../../pungo-ui/PungoButton";
import PungoSectionCard from "../../pungo-ui/PungoSectionCard";
import PungoNonClickable from "pungo-ui/PungoNonClickable";

import styles from "./index.module.scss";
import classNames from "classnames";

const ResultPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { surveyScore } = useSelector(selectSurvey) || 0;
  const [isSelected, setIsSelected] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    if (surveyScore <= 18) {
      setIsSelected("initial");
    } else if (surveyScore > 18 && surveyScore <= 27) {
      setIsSelected("medium");
    } else if (surveyScore > 27) {
      setIsSelected("advanced");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyScore]);

  const getResultText = () => {
    if (surveyScore <= 18) {
      return <Trans>{t("results.initial", { score: surveyScore })}</Trans>;
    } else if (surveyScore > 18 && surveyScore <= 27) {
      return <Trans>{t("results.medium", { score: surveyScore })}</Trans>;
    } else if (surveyScore > 27) {
      return <Trans>{t("results.advanced", { score: surveyScore })}</Trans>;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <PungoSectionCard title={t("resultSection.resultLabel")}>
          <div className={styles.resultContainer}>
            <img alt="Results" src={ResultsImage} className={styles.image} />
            <div className={styles.nonClickableSection}>
              <PungoNonClickable
                classNames={classNames(styles.nonClickable, {
                  [styles.selected]: isSelected === "initial",
                })}
              >
                {t("resultSection.initial")}
              </PungoNonClickable>

              <PungoNonClickable
                classNames={classNames(styles.nonClickable, {
                  [styles.selected]: isSelected === "medium",
                })}
              >
                {t("resultSection.mediumLabel")}
              </PungoNonClickable>
              <PungoNonClickable
                classNames={classNames(styles.nonClickable, {
                  [styles.selected]: isSelected === "advanced",
                })}
              >
                {t("resultSection.advancedLabel")}
              </PungoNonClickable>
            </div>
          </div>
        </PungoSectionCard>
      </div>

      <div className={styles.resultSection}>
        <p>{t("results.greeting")}</p>

        {getResultText()}
      </div>
      <PungoButton
        label={`${t("goToAstro")}`}
        onClick={() => navigate("/plan")}
        color="secondary"
        iconPosition="right"
        icon={<ArrowForwardIos />}
        classNames={styles.goToAstroButton}
      />
    </div>
  );
};

export default ResultPage;
