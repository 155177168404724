import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

interface State {
  isVerifyingAccount: boolean;
}

const initialState: State = {
  isVerifyingAccount: false,
};

const accountSlice = createSlice({
  name: "accountData",
  initialState,
  reducers: {
    setVerifyingAccount: (state, action: PayloadAction<boolean>) => {
      state.isVerifyingAccount = action.payload;
    },
  },
});
export const { setVerifyingAccount } = accountSlice.actions;

export default accountSlice.reducer;

export const selectAccountVerifyingLoader = (state: RootState): boolean =>
  state.accountManagement.isVerifyingAccount;

