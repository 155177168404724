import React from "react";
import { Trans, useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const TermsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.element}>
        <div className={styles.title}>{t("manageProfile.privacyLabel")}</div>
        <div className={styles.content}>
          <div className={styles.text}>
            <div className={styles.message}>
              <Trans>{t("manageProfile.privacyTerms010_01_Txt")}</Trans>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.label}>
              <Trans>{t("manageProfile.privacyTerms020_Label")}</Trans>
            </div>
            <div className={styles.message}>
              <Trans>{t("manageProfile.privacyTerms020_01_Txt")}</Trans>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.label}>
              <Trans>{t("manageProfile.privacyTerms030_Label")}</Trans>
            </div>
            <div className={styles.message}>
              <Trans>{t("manageProfile.privacyTerms030_01_Txt")}</Trans>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.label}>
              <Trans>{t("manageProfile.privacyTerms040_Label")}</Trans>
            </div>
            <div className={styles.message}>
              <Trans>{t("manageProfile.privacyTerms040_01_Txt")}</Trans>
            </div>
            <div className={styles.message}>
              <Trans>{t("manageProfile.privacyTerms040_02_Txt")}</Trans>
            </div>
            <div className={styles.message}>
              <Trans>{t("manageProfile.privacyTerms040_03_Txt")}</Trans>
            </div>
            <div className={styles.message}>
              <Trans>{t("manageProfile.privacyTerms040_04_Txt")}</Trans>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.label}>
              <Trans>{t("manageProfile.privacyTerms050_Label")}</Trans>
            </div>
            <div className={styles.message}>
              <Trans>{t("manageProfile.privacyTerms050_01_Txt")}</Trans>
            </div>
            <ol>
              <li>
                <div className={styles.message}>
                  <Trans>{t("manageProfile.privacyTerms050_01_01_Txt")}</Trans>
                </div>
              </li>
              <li>
                <div className={styles.message}>
                  <Trans>{t("manageProfile.privacyTerms050_01_02_Txt")}</Trans>
                </div>
              </li>
              <li>
                <div className={styles.message}>
                  <Trans>{t("manageProfile.privacyTerms050_01_03_Txt")}</Trans>
                </div>
              </li>
              <li>
                <div className={styles.message}>
                  <Trans>{t("manageProfile.privacyTerms050_01_04_Txt")}</Trans>
                </div>
              </li>
              <li>
                <div className={styles.message}>
                  <Trans>{t("manageProfile.privacyTerms050_01_05_Txt")}</Trans>
                </div>
              </li>
              <li>
                <div className={styles.message}>
                  <Trans>{t("manageProfile.privacyTerms050_01_06_Txt")}</Trans>
                </div>
              </li>
              <li>
                <div className={styles.message}>
                  <Trans>{t("manageProfile.privacyTerms050_01_07_Txt")}</Trans>
                </div>
              </li>
              <li>
                <div className={styles.message}>
                  <Trans>{t("manageProfile.privacyTerms050_01_08_Txt")}</Trans>
                </div>
              </li>
            </ol>
          </div>
          <div className={styles.text}>
            <div className={styles.label}>
              <Trans>{t("manageProfile.privacyTerms060_Label")}</Trans>
            </div>
            <div className={styles.message}>
              <Trans>{t("manageProfile.privacyTerms060_01_Txt")}</Trans>
            </div>
            <div className={styles.message}>
              <Trans>{t("manageProfile.privacyTerms060_02_Txt")}</Trans>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.label}>
              <Trans>{t("manageProfile.privacyTerms070_Label")}</Trans>
            </div>
            <div className={styles.message}>
              <Trans>{t("manageProfile.privacyTerms070_01_Txt")}</Trans>
            </div>
            <div className={styles.message}>
              <Trans>{t("manageProfile.privacyTerms070_02_Txt")}</Trans>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.label}>
              <Trans>{t("manageProfile.privacyTerms080_Label")}</Trans>
            </div>
            <div className={styles.message}>
              <Trans>{t("manageProfile.privacyTerms080_01_Txt")}</Trans>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
