import { AppDispatch } from "../../store";
import {
  showSnackbar,
  clearSnackbar,
  ISnackbarState,
} from "../slices/snackbar";

export const showSnackbarAction =
  (notif: ISnackbarState) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(showSnackbar(notif));
  };

export const clearSnackbarAction =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(clearSnackbar());
  };
