import React, { useState } from "react";
import { Slider } from "@mui/material";

import styles from "./index.module.scss";
import PungoDemoInput from "pungo-ui/PungoDemoInput";

export interface IEnergyInfo {
  id: number;
  value: number;
}
interface PungoSliderProps {
  description: string;
  minValue: number;
  maxValue: number;
  step: number;
  defaultValue: number;
  setSelectedValue: (value:number) => void;
  sliderValue?: number;
  inputValue?: number;
  unit: string;
}
const PungoSlider: React.FC<PungoSliderProps> = (props) => {
  const {
    description,
    minValue = 0,
    maxValue = 100,
    step = 0.5,
    defaultValue,
    setSelectedValue,
    unit = "psi",
  } = props;
  const [sliderValue, setSliderValue] = useState<number>(defaultValue);
  const marks = [
    {
      value: minValue,
      label: minValue,
    },
    {
      value: maxValue,
      label: maxValue,
    },
  ];

  const handleChangeSliderValue = (newValue: any, type: string) => {
    if (type === "slider") {
      const newSliderValue = Number(newValue.target.value);
      setSelectedValue(newSliderValue);
      setSliderValue(newSliderValue);
    } else {
      const newInputValue = Number(newValue);
      setSelectedValue(newInputValue);
      setSliderValue(newInputValue);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.description}>{description}</div>
      <div className={styles.sliderContainer}>
        <Slider
          step={step}
          min={minValue}
          max={maxValue}
          marks={marks}
          value={sliderValue}
          color={"primary"}
          valueLabelDisplay="on"
          onChange={(e) => handleChangeSliderValue(e, "slider")}
          track={false}
        />
      </div>
      <div className={styles.sliderValue}>
        <PungoDemoInput
          type={"number"}
          value={sliderValue}
          onChangeNumber={(e) => handleChangeSliderValue(e, "input")}
          height="25px"
          width="80px"
        />
        <div className={styles.sliderUnit}>{unit}</div>
      </div>
    </div>
  );
};

export default PungoSlider;
