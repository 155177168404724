import React from "react";
import PungoSnackbar from "../../pungo-ui/PungoSnackbar";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectSnackbar } from "../../store/slices/snackbar";

const SnackbarContainer: React.FC = () => {
  const { text, type } = useSelector(selectSnackbar);
  const { t } = useTranslation();

  return <PungoSnackbar type={type} text={t(text)} />;
};

export default SnackbarContainer;
