import React from "react";
import { TextField } from "@mui/material";

import styles from "./index.module.scss";
import colors from "../../styles/export.module.scss";

interface PungoDemoInputProps {
  name?: string;
  helperText?: string | undefined;
  type: "number" | "text";
  width?: string;
  height?: string;
  onChangeText?: (value: string) => void;
  onChangeNumber?: (value: number) => void;
  value: string | number | null;
  defaultValue?: string | number;
  id?: string;
}

const PungoDemoInput: React.FC<PungoDemoInputProps> = (props) => {
  const {
    name,
    helperText,
    type,
    width,
    height,
    onChangeText = () => null,
    onChangeNumber = () => null,
    value,
    defaultValue,
    id,
  } = props;

  return (
    <div className={styles.container}>
      <TextField
        id={id}
        label={name}
        value={value}
        defaultValue={defaultValue}
        type={type}
        variant="outlined"
        error={!!helperText}
        helperText={helperText}
        onChange={
          type === "text"
            ? (event) => onChangeText(event.target.value)
            : (e) => onChangeNumber(Number(e.target.value))
        }
        inputProps={{
          step: 0.5,
          border: "0px",
          textalign: "center",
        }}
        InputProps={{
          style: {
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            fontWeight: "400",
            fontSize: "16px",
            color: colors.blue3,
            padding: "0px",
            border: "0px",
            width: width,
            height: height,
          },
        }}
      />
    </div>
  );
};

export default PungoDemoInput;
