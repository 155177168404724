import React, { useEffect, useState } from "react";
import { useAppDispatch } from "app/hooks";
import PungoInput from "pungo-ui/PungoInput";
import PungoModal from "pungo-ui/PungoModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  fetchUsageById,
  fetchUsageResume,
  fetchUserDetailsById,
} from "store/actions/userActions";
import {
  clearUserDataById,
  selectAllRoles,
  selectGeneralUsage,
  selectUsageById,
  selectUserDetailById,
} from "store/slices/user";
import AdminTable from "../common/AdminTable";
import { columnsForUsageTable } from "../tableData";

import styles from "./index.module.scss";

const AdminUsageTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const usageData = useSelector(selectGeneralUsage);
  const detailedData = useSelector(selectUsageById);
  const detailedUserData = useSelector(selectUserDetailById);
  const rolesList = useSelector(selectAllRoles);

  const [showUsageModal, setShowUsageModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUsageId, setSelectedUsageId] = useState<number | undefined>();
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>();

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    dispatch(fetchUsageResume());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedUsageId) {
      dispatch(fetchUsageById(selectedUsageId));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsageId]);

  useEffect(() => {
    setSelectedUserId(detailedData.callDetails.userId);
  }, [detailedData]);

  useEffect(() => {
    setName(detailedUserData?.name || "");
    setCompany(detailedUserData?.companyName || "");
    setEmail(detailedUserData?.email || "");
    setRole(detailedUserData?.role || "");
  }, [detailedUserData]);

  useEffect(() => {
    if (selectedUserId) {
      dispatch(fetchUserDetailsById(selectedUserId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserId]);

  const clearForm = () => {
    setSelectedUserId(undefined);
    setName("");
    setCompany("");
    setEmail("");
    setRole("");
    setPassword("");
  };

  const handleOnClose = () => {
    setShowUsageModal(false);
    setSelectedUsageId(undefined);
  };

  const handleOnCloseUserModal = () => {
    dispatch(clearUserDataById());
    setShowUserModal(false);
    clearForm();
  };

  const getUserDetailsModal = () => (
    <PungoModal
      open={showUserModal}
      title={`${t("admin.userDetailsLabel")}`}
      handleClose={handleOnCloseUserModal}
      primaryAction={{
        label: "Ok",
        onClick: handleOnCloseUserModal,
      }}
    >
      <div className={styles.modal}>
        <PungoInput
          name={`${t("user.nameLabel")}`}
          value={name}
          onChange={setName}
          disabled
        />
        <PungoInput
          name={`${t("user.companyLabel")}`}
          value={company}
          disabled
          onChange={setCompany}
        />
        <PungoInput
          name={`${t("user.emailLabel")}`}
          value={email}
          onChange={setEmail}
          disabled
        />
        <PungoInput
          name={`${t("user.roleLabel")}`}
          value={role}
          onChange={setRole}
          disabled
          type="select"
          options={rolesList.map(({ role }) => {
            return { value: role, label: role };
          })}
        />
        <PungoInput
          name={`${t("user.passwordLabel")}`}
          value={password}
          onChange={setPassword}
          disabled
          type="password"
        />
      </div>
    </PungoModal>
  );

  return (
    <div>
      <AdminTable
        columns={columnsForUsageTable}
        data={usageData || []}
        actions={["stats", "user"]}
        handleOnSelection={setSelectedUsageId}
        handleOnUsage={setShowUsageModal}
        handleOnUser={setShowUserModal}
      />
      <PungoModal
        open={showUsageModal}
        classNames={styles.detailsModal}
        title={`${t("admin.detailsLabel")}`}
        handleClose={handleOnClose}
        primaryAction={{
          label: "Ok",
          onClick: handleOnClose,
        }}
      >
          <code className={styles.detailsModalChild}>
            <pre>{JSON.stringify(detailedData, null, 4)}</pre>
          </code>
      </PungoModal>
      {getUserDetailsModal()}
    </div>
  );
};

export default AdminUsageTable;
