import React from "react";
import { useTranslation } from "react-i18next";
import json2mq from "json2mq";

import PungoTeamMemberCard from "../../../../pungo-ui/PungoTeamMemberCard";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import CEOPhoto from "../../../../assets/PungoIcons/CEOPhoto.svg";
import CTOPhoto from "../../../../assets/PungoIcons/CTOPhoto.svg";
import PMPhoto from "../../../../assets/PungoIcons/PMPhoto.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import breakpoints from "../../../../styles/export.module.scss";
import PungoCustomizedCarousel from "../../../../pungo-ui/PungoCustomizedCarousel";

import styles from "./index.module.scss";

const TeamSection: React.FC = () => {
  const { t } = useTranslation();

  const desktopView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.lg,
    })
  );

  const teamMembers = [
    {
      id: "team1",
      link: "https://www.linkedin.com/in/santiagozapata/",
      photo: CEOPhoto,
      name: "Santiago Zapata",
      position: t("CEOMember.position"),
      description: t("CEOMember.description"),
    },
    {
      id: "team2",
      link: "https://www.linkedin.com/in/juan-carlos-bazante-a6434376/",
      photo: CTOPhoto,
      name: "Juan Carlos Bazante",
      position: t("CTOMember.position"),
      description: t("CTOMember.description"),
    },
    {
      id: "team3",
      link: "https://www.linkedin.com/in/robinson-cabrera-590272236/",
      photo: PMPhoto,
      name: "Robinson Cabrera",
      position: t("DSMember.position"),
      description: t("DSMember.description"),

    },
  ];

  const getTeamMembers = () =>
    teamMembers.map(({ id, link, photo, name, position, description }) => (
      <div key={id} className={styles.cardContainer}>
        <PungoTeamMemberCard
          link={link}
          photo={photo}
          name={name}
          position={position}
          description={description}
          key={name}
        />
      </div>
    ));

  return (
    <PungoSectionCard title={`${t("sectionLabel.team")}`} id="team">
      <div className={styles.teamContainer}>
        {desktopView ? (
          getTeamMembers()
        ) : (
          <div className={styles.carouselContainer}>
            <PungoCustomizedCarousel slides={getTeamMembers()} height={500} />
          </div>
        )}
      </div>
    </PungoSectionCard>
  );
};

export default TeamSection;
