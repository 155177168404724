import React from "react";

import { Button, IconButton } from "@mui/material";

import classnames from "classnames";
import styles from "./index.module.scss";

interface PungoPlanButtonProps {
  label?: string | undefined;
  type?: "contained" | "outlined" | "icon" | "text";
  onClick: (() => Window | null) | ((event: any) => void) | (() => void);
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  disabled?: boolean;
  color?: "primary" | "secondary" | "tertiary";
  classNames?: string;
}

const PungoPlanButton: React.FC<PungoPlanButtonProps> = (props) => {
  const {
    label,
    type = "contained",
    onClick,
    icon,
    disabled,
    color = "primary",
    classNames,
    iconPosition = "left",
  } = props;

  const getClassNames = () =>
    classnames(styles.container, classNames, {
      [styles.containedButton]: type === "contained",
      [styles.outlinedButton]: type === "outlined",
      [styles.disabled]: disabled,
      [styles.primaryColor]: color === "primary",
      [styles.secondaryColor]: color === "secondary",
      [styles.tertiaryColor]: color === "tertiary",
      [styles.text]: type === "text",
    });

  const getButton = () => {
    switch (type) {
      case "icon":
        return (
          <IconButton
            disabled={disabled}
            className={styles.icon}
            onClick={onClick}
          >
            {icon}
          </IconButton>
        );
      default:
        return (
          <Button
            startIcon={iconPosition === "left" ? icon : null}
            endIcon={iconPosition === "right" ? icon : null}
            onClick={onClick}
            variant={type}
            disabled={disabled}
            className={getClassNames()}
          >
            {label}
          </Button>
        );
    }
  };

  return getButton();
};

export default PungoPlanButton;
