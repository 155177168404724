import React from "react";

import { clearSnackbarAction } from "../../store/actions/snackbarActions";
import { Snackbar } from "@mui/material";
import { useAppDispatch } from "app/hooks";

import classnames from "classnames";
import styles from "./index.module.scss";

interface PungoSnackbarProps {
  type: "error" | "success";
  text: string;
}

const PungoSnackbar: React.FC<PungoSnackbarProps> = (props) => {
  const { type = "success", text } = props;

  const dispatch = useAppDispatch();

  const getClassNames = () =>
    classnames(styles.snackbarContainer, {
      [styles.success]: type === "success",
      [styles.error]: type === "error",
    });

  return (
    <Snackbar
      open={!!text}
      autoHideDuration={6000}
      message={text}
      onClose={() => {
        dispatch(clearSnackbarAction());
      }}
      className={getClassNames()}
    />
  );
};

export default PungoSnackbar;
