import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

import PungoDemoAccordion from "../../../../pungo-ui/PungoDemoAccordion";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import PungoDemoResultsCard from "../../../../pungo-ui/PungoDemoResultsCard/index";
import Warning from "../../../../assets/PungoIcons/Warning.svg";
import Coin from "../../../../assets/PungoIcons/Coin.svg";
import { selectResults } from "store/slices/plan";

import colors from "../../../../styles/export.module.scss";
import styles from "./index.module.scss";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const ResultsSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isSpanishSelected = i18n.language === "es";

  const {
    wellName,
    corrosionResult,
    saturationIndexResult,
    wellCriticalityResultOutput,
    chemicalOptimizationResult,
  } = useSelector(selectResults);

  const translateResults = (level: string) => {
    switch (level) {
      case "low":
        return isSpanishSelected ? "Bajo" : "Low";
      case "medium":
        return isSpanishSelected ? "Medio" : "Medium";
      case "high":
        return isSpanishSelected ? "Alto" : "High";
      case "very_high":
        return isSpanishSelected ? "Muy Alto" : "Very High";
      case "Sobre-dosificado":
        return isSpanishSelected ? level : "Over-dosed";
      case "Sub-dosificado":
        return isSpanishSelected ? level : "Under-dosed";
      default:
        return "";
    }
  };

  const getColorForResults = (level: string) => {
    switch (level) {
      case "low":
        return colors.green2;
      case "medium":
        return colors.yellow1;
      case "high":
        return colors.red1;
      case "very_high":
        return colors.red2;
      case "Sobre-dosificado":
        return colors.red3;
      case "Sub-dosificado":
        return colors.red1;
      default:
        return "";
    }
  };

  const getEmptyState = () => (
    <div className={styles.emptyState}>
      {t("DemoPage.ResultsSection.emptyState")}
    </div>
  );

  const corrosionData = {
    labels: corrosionResult?.depth,
    datasets: [
      {
        data: corrosionResult?.corrosion,
        borderWidth: 1,
        borderColor: colors.red1,
        tension: 0.4,
        pointStyle: "circle",
        pointBackgroundColor: colors.red1,
        backgroundColor: colors.red1,
        pointBorderColor: colors.red1,
        pointRadius: 1.5,
      },
    ],
  };

  const lineChartConfigForCorrosion = {
    indexAxis: "y" as const,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => {
            return wellName;
          },
          label: (ctx: any) => {
            return [
              `${t("DemoPage.ResultsSection.corrosionSpeedAxis")} = ${
                corrosionResult?.corrosion[ctx.dataIndex].toFixed(2) || 0
              }`,
              `${t("DemoPage.ResultsSection.depthAxis")} = ${
                corrosionResult?.depth[ctx.dataIndex] || 0
              }`,
              `${t("DemoPage.ResultsSection.pressionAxis")} = ${
                corrosionResult?.pressure[ctx.dataIndex] || 0
              }`,
              `${t("DemoPage.ResultsSection.temperatureAxis")} = ${
                corrosionResult?.temperature[ctx.dataIndex] || 0
              }`,
              `${t("DemoPage.ResultsSection.corrosionRisk")} = ${
                translateResults(
                  corrosionResult?.corrosionRisk[ctx.dataIndex]
                ) || ""
              }`,
            ];
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        display: true,
        position: "top" as const,
        title: {
          display: true,
          color: colors.gray1,
          text: t("DemoPage.ResultsSection.corrosionSpeedAxis"),
        },
        grid: {
          color: (ctx: any) => {
            if (ctx.index === 0) {
              return `${colors.gray1}`;
            } else {
              return "transparent";
            }
          },
        },
        ticks: {
          color: colors.gray1,
        },
      },

      y: {
        display: true,
        title: {
          display: true,
          color: colors.gray1,
          text: t("DemoPage.ResultsSection.depthAxis"),
        },
        grid: {
          color: (ctx: any) => {
            if (ctx.index === 0) {
              return `${colors.gray1}`;
            } else {
              return "transparent";
            }
          },
        },
        ticks: {
          color: colors.gray1,
        },
      },
    },
  };

  const saturationData = {
    labels: saturationIndexResult?.depth,

    datasets: [
      {
        data: saturationIndexResult?.calciteSaturationIndex,
        borderWidth: 1,
        borderColor: colors.red1,
        tension: 0.4,
        pointStyle: "circle",
        pointBackgroundColor: colors.red1,
        backgroundColor: colors.red1,
        pointBorderColor: colors.red1,
        pointRadius: 1.5,
      },
    ],
  };

  const lineChartConfigForSaturation = {
    indexAxis: "y" as const,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => {
            return wellName;
          },
          label: (ctx: any) => {
            return [
              `${t("DemoPage.ResultsSection.saturationIndex")} = ${
                saturationIndexResult?.calciteSaturationIndex[
                  ctx.dataIndex
                ].toFixed(2) || 0
              }`,
              `${t("DemoPage.ResultsSection.depthAxis")} = ${
                saturationIndexResult?.depth[ctx.dataIndex] || 0
              }`,
              `${t("DemoPage.ResultsSection.pressionAxis")} = ${
                saturationIndexResult?.pressure[ctx.dataIndex] || 0
              }`,
              `${t("DemoPage.ResultsSection.temperatureAxis")} = ${
                saturationIndexResult?.temperature[ctx.dataIndex] || 0
              }`,
              `${t("DemoPage.ResultsSection.solids")} = ${
                saturationIndexResult?.solids[ctx.dataIndex] || 0
              }`,
              `${t(
                "DemoPage.ResultsSection.incrustationRisk"
              )} = ${translateResults(
                saturationIndexResult?.encrustationRisk[ctx.dataIndex] || ""
              )}`,
            ];
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        display: true,
        position: "top" as const,
        title: {
          display: true,
          color: colors.gray1,
          text: t("DemoPage.ResultsSection.saturationIndex"),
        },
        grid: {
          color: (ctx: any) => {
            if (ctx.index === 0) {
              return `${colors.gray1}`;
            } else {
              return "transparent";
            }
          },
        },
        ticks: {
          color: colors.gray1,
        },
      },

      y: {
        display: true,
        title: {
          display: true,
          color: colors.gray1,
          text: t("DemoPage.ResultsSection.depthAxis"),
        },
        grid: {
          color: (ctx: any) => {
            if (ctx.index === 0) {
              return `${colors.gray1}`;
            } else {
              return "transparent";
            }
          },
        },
        ticks: {
          color: colors.gray1,
        },
      },
    },
  };

  const getCorrosionResults = () => (
    <div className={styles.resultsContainer}>
      <div className={styles.section}>
        <div className={styles.label}>
          {t("DemoPage.ResultsSection.corrosionSpeed")}
        </div>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.headCorrosion") as string}
            result={`${corrosionResult.corrosionHead} MPY`}
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.bottomCorrosion") as string}
            result={`${corrosionResult.corrosionDepth} MPY`}
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.maxCorrosion") as string}
            result={`${corrosionResult.corrosionMax} MPY`}
            color={colors.blue4}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>
          {t("DemoPage.ResultsSection.corrosionRisk")}
        </div>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.headRisk") as string}
            result={translateResults(corrosionResult.corrosionRiskHead)}
            color={getColorForResults(corrosionResult.corrosionRiskHead)}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.bottomRisk") as string}
            result={translateResults(corrosionResult.corrosionRiskDepth)}
            color={getColorForResults(corrosionResult.corrosionRiskDepth)}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.maxRisk") as string}
            result={translateResults(corrosionResult.corrosionRiskMax)}
            color={getColorForResults(corrosionResult.corrosionRiskMax)}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>
          {t("DemoPage.ResultsSection.corrosionProfile")}
        </div>
        <div className={styles.chartContainer}>
          <Line data={corrosionData} options={lineChartConfigForCorrosion} />
        </div>
      </div>
    </div>
  );

  const getSaturationResults = () => (
    <div className={styles.resultsContainer}>
      <div className={styles.section}>
        <div className={styles.label}>
          {t("DemoPage.ResultsSection.saturationRate")}
        </div>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.headSaturation") as string}
            result={saturationIndexResult.saturationIndexHead}
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={
              t("DemoPage.ResultsSection.bottomSaturation") as string
            }
            result={saturationIndexResult.saturationIndexDepth}
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.maxSaturation") as string}
            result={saturationIndexResult.saturationIndexMax}
            color={colors.blue4}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>
          {t("DemoPage.ResultsSection.incrustationRisk")}
        </div>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.headRisk") as string}
            result={translateResults(
              saturationIndexResult.encrustationRiskHead
            )}
            color={getColorForResults(
              saturationIndexResult.encrustationRiskHead
            )}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.bottomRisk") as string}
            result={translateResults(
              saturationIndexResult.encrustationRiskDepth
            )}
            color={getColorForResults(
              saturationIndexResult.encrustationRiskDepth
            )}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.maxRisk") as string}
            result={translateResults(saturationIndexResult.encrustationRiskMax)}
            color={getColorForResults(
              saturationIndexResult.encrustationRiskMax
            )}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>
          {t("DemoPage.ResultsSection.saturationProfile")}
        </div>
        <div className={styles.chartContainer}>
          <Line data={saturationData} options={lineChartConfigForSaturation} />
        </div>
      </div>
    </div>
  );

  const getChemicalOptimization = () => (
    <div className={styles.resultsContainer}>
      <div className={styles.section}>
        <div className={styles.label}>
          {t("DemoPage.OptimizationSection.anticorrosive")}
        </div>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={
              t("DemoPage.OptimizationSection.currentDose") as string
            }
            result={`${chemicalOptimizationResult.actualAnticorrosiveDose} GDP`}
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={
              t("DemoPage.OptimizationSection.recommendedDose") as string
            }
            result={`${chemicalOptimizationResult.recommendedAnticorrosiveDose} GDP`}
            color={colors.green2}
          />
          <PungoDemoResultsCard
            description={
              t("DemoPage.OptimizationSection.inyectionState") as string
            }
            result={chemicalOptimizationResult.anticorrosiveInjectionStatus}
            color={
              chemicalOptimizationResult.anticorrosiveInjectionStatus ===
              "Sobre-dosificado"
                ? colors.red3
                : colors.red1
            }
          />
        </div>
        <div className={styles.graphSection}>
          <img
            className={styles.graph}
            src={
              chemicalOptimizationResult.anticorrosiveInjectionStatus ===
              "Sobre-dosificado"
                ? Coin
                : Warning
            }
            alt="Warning"
          />

          <div className={styles.advice}>
            <PungoDemoResultsCard
              result={
                chemicalOptimizationResult.anticorrosiveInjectionStatus ===
                "Sobre-dosificado" ? (
                  <Trans>
                    {t("DemoPage.OptimizationSection.corrosionSaving", {
                      anticorrosiveSaving:
                        chemicalOptimizationResult.potentialAnticorrosiveSavings,
                    })}
                  </Trans>
                ) : (
                  <Trans>
                    {t("DemoPage.OptimizationSection.corrosionRisk")}
                  </Trans>
                )
              }
              color={
                chemicalOptimizationResult.anticorrosiveInjectionStatus ===
                "Sobre-dosificado"
                  ? colors.red3
                  : colors.red1
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.label}>
          {t("DemoPage.OptimizationSection.antiescale")}
        </div>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={
              t("DemoPage.OptimizationSection.currentAntiscaleDose") as string
            }
            result={`${chemicalOptimizationResult.actualAntiscaleDose} GDP`}
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={
              t(
                "DemoPage.OptimizationSection.recommendedAntiscaleDose"
              ) as string
            }
            result={`${chemicalOptimizationResult.recommendedAntiscaleDose} GDP`}
            color={colors.green2}
          />
          <PungoDemoResultsCard
            description={
              t(
                "DemoPage.OptimizationSection.inyectionAntiscaleState"
              ) as string
            }
            result={chemicalOptimizationResult.antiscaleInjectionStatus}
            color={
              chemicalOptimizationResult.antiscaleInjectionStatus ===
              "Sobre-dosificado"
                ? colors.red3
                : colors.red1
            }
          />
        </div>
        <div className={styles.graphSection}>
          <img
            className={styles.graph}
            src={
              chemicalOptimizationResult.antiscaleInjectionStatus ===
              "Sobre-dosificado"
                ? Coin
                : Warning
            }
            alt="Warning"
          />
          <div className={styles.advice}>
            <PungoDemoResultsCard
              result={
                chemicalOptimizationResult.antiscaleInjectionStatus ===
                "Sobre-dosificado" ? (
                  <Trans>
                    {t("DemoPage.OptimizationSection.antiscaleSaving", {
                      antiscaleSaving:
                        chemicalOptimizationResult.potentialAntiscaleSavings,
                    })}
                  </Trans>
                ) : (
                  <Trans>
                    {t("DemoPage.OptimizationSection.antiscaleRisk")}
                  </Trans>
                )
              }
              color={
                chemicalOptimizationResult.antiscaleInjectionStatus ===
                "Sobre-dosificado"
                  ? colors.red3
                  : colors.red1
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
  const getWellResults = () => (
    <div className={styles.resultsContainer}>
      <div className={styles.section}>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.wellProduction") as string}
            result={wellCriticalityResultOutput.bopd}
            color={colors.green2}
          />
          <PungoDemoResultsCard
            description={
              t("DemoPage.ResultsSection.wellCriticalityRate") as string
            }
            result={wellCriticalityResultOutput.totalCriticalityIndex}
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={
              t("DemoPage.ResultsSection.chemicalTreatment") as string
            }
            result={translateResults(
              wellCriticalityResultOutput.treatmentPriority
            )}
            color={getColorForResults(
              wellCriticalityResultOutput.treatmentPriority
            )}
          />
        </div>
      </div>
      <div className={styles.section}>
        <Trans>{t("DemoPage.ResultsSection.wellCriticalityText")}</Trans>
      </div>
    </div>
  );
  return (
    <PungoSectionCard title={t("DemoPage.ResultsSection.results")}>
      {!wellName ? (
        getEmptyState()
      ) : (
        <div className={styles.container}>
          <PungoDemoAccordion
            title={t("DemoPage.ResultsSection.corrosion")}
            content={getCorrosionResults()}
          />
          <PungoDemoAccordion
            title={t("DemoPage.ResultsSection.saturationRate")}
            content={getSaturationResults()}
          />
          <PungoDemoAccordion
            title={t("DemoPage.ResultsSection.wellCriticality")}
            content={getWellResults()}
          />
          <PungoDemoAccordion
            title={t("DemoPage.OptimizationSection.optimization")}
            content={getChemicalOptimization()}
          />
        </div>
      )}
    </PungoSectionCard>
  );
};

export default ResultsSection;
