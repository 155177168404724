import React from "react";

import PungoButton from "pungo-ui/PungoButton";

import classnames from "classnames";
import styles from "./index.module.scss";

interface SelectableTabsProps {
  labels: string[];
  onSelect: (tabIndex: number) => void;
  selectedIndex: number;
}

const SelectableTabs: React.FC<SelectableTabsProps> = (props) => {
  const { labels, onSelect, selectedIndex } = props;

  return (
    <div className={styles.tabsContainer}>
        {labels.map((label, index) => (
          <PungoButton
            key={index}
            classNames={classnames(styles.button, {
              [styles.selectedMenu]: index === selectedIndex,
            })}
            label={label}
            onClick={() => onSelect(index)}
          />
        ))}
      </div>
  );
};

export default SelectableTabs;
