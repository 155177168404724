import React from "react";

import colors from "../../styles/export.module.scss";
import styles from "./index.module.scss";

interface PungoWorkflowCardProps {
  image: string;
  title: string;
  description: string;
  color: string;
}

const PungoWorkflowCard: React.FC<PungoWorkflowCardProps> = (props) => {
  const { image, title, description, color = colors.green4 } = props;

  return (
    <div className={styles.container}>
      <img src={image} alt="Workflow card" className={styles.image} />
      <div className={styles.header} style={{ color: color }}>
        {title}
      </div>
      {description && <div className={styles.description}>{description}</div>}
    </div>
  );
};

export default PungoWorkflowCard;
