import React from "react";
import { useTranslation } from "react-i18next";
import PungoButton from "../../../../pungo-ui/PungoButton";
import PungoClickable from "../../../../pungo-ui/PungoClickable";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import styles from "./index.module.scss";
import Whatsapp from "../../../../assets/PungoIcons/Whatsapp.svg";

const ContactUsSection: React.FC = () => {
  const { t } = useTranslation();

  const getBookingPage = () =>
    window.open("https://marketing.pungoapp.com/meetings/santiago-zapata2588/santiago-zapata");

  const sendWhastapp = () => {
    let url:string = "https://api.whatsapp.com/send/?";
    let phone:string = "phone="+"447442697610";
    let msg:string = `&text=${t("sectionContent.whatsAppMessage")}`;
    let tale:string = "&type=phone_number&app_absent=0";

    msg = msg.replaceAll(" ","+");
    url += phone + msg + tale;
    
    window.open(url, "_blank", "noreferrer")
  };


  return (
    <>
      <PungoSectionCard
        title={t("sectionLabel.contactUs")}
        description={`${t("sectionContent.contactUs")}`}
        id="contactUs"
      >
        <div className={styles.container}>
          <PungoButton
            label={`${t("bookAMeetingLabel")}`}
            onClick={getBookingPage}
            classNames={styles.bookingButton}
            color="secondary"
          />
        </div>
      </PungoSectionCard>

      <div className={styles.contactLinksContainer}>
        <PungoClickable
          link="https://www.linkedin.com/company/pungosolutions/"
          classNames={styles.linkedInButton}
        >
          in
        </PungoClickable>
        <img src={Whatsapp} alt="Whatsapp call" className={styles.whatsappButton} onClick={() => sendWhastapp()} />
      </div>
    </>
  );
};

export default ContactUsSection;
