import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { IPaymentTypes, IUsageTypes } from "store/actions/userActions";

interface IUser {
  id: number;
  status: string;
  keycloakUserId: string;
  username: string;
  name: string;
  email: string;
  emailVerificationCode: string;
  passwordChangeCode: string;
  emailVerified: boolean;
  role: string;
  surveyCompleted: boolean;
  companyName: string;
  planUsageType: IUsageTypes;
  planRemainingUsages: number;
  planEndDate: string;
  planPaymentType: IPaymentTypes;
}
interface ICallDetail {
  userId: number;
  userName: string;
  ip: string;
  requestUri: string;
  time: string;
}
interface IUsage {
  id: number;
  callType: string;
  callDetails: ICallDetail;
  input: object;
  output: object;
}

interface IRole {
  role: string;
}

const emptyUser: IUser = {
  id: 0,
  status: "",
  keycloakUserId: "",
  username: "",
  name: "",
  email: "",
  emailVerificationCode: "",
  passwordChangeCode: "",
  emailVerified: false,
  role: "",
  surveyCompleted: false,
  companyName: "",
  planUsageType: null,
  planRemainingUsages: 0,
  planEndDate: "",
  planPaymentType: null,
};

const emptyCallDetail = {
  userId: 0,
  userName: "",
  ip: "",
  requestUri: "",
  time: "",
};

const emptyUsage: IUsage = {
  id: 0,
  callDetails: emptyCallDetail,
  callType: "",
  input: {},
  output: {},
};

const emptyRole: IRole = {
  role: "",
};

const initialState = {
  users: [emptyUser],
  userDetailsById: emptyUser,
  roles: [emptyRole],
  usage: [emptyUsage],
  usageByUserId: [emptyUsage],
  usageById: emptyUsage,
};

const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    clearUserManagement: (state) => {
      state.users = [];
      state.usageByUserId = [];
      state.roles = [];
      state.userDetailsById = emptyUser;
      state.usage = [];
      state.usageById = emptyUsage;
    },
    clearUsageDataByUser: (state) => {
      state.usageByUserId = [];
    },
    clearUserDataById: (state) => {
      state.userDetailsById = emptyUser;
    },
    saveUsers: (state, action: PayloadAction<IUser[]>) => {
      state.users = action.payload;
    },
    saveUserDetailById: (state, action: PayloadAction<IUser>) => {
      state.userDetailsById = action.payload;
    },
    saveRoles: (state, action: PayloadAction<IRole[]>) => {
      state.roles = action.payload;
    },
    saveUsage: (state, action: PayloadAction<IUsage[]>) => {
      state.usage = action.payload;
    },
    saveUsageByUserId: (state, action: PayloadAction<IUsage[]>) => {
      state.usageByUserId = action.payload;
    },
    saveUsageById: (state, action: PayloadAction<IUsage>) => {
      state.usageById = action.payload;
    },
  },
});
export const {
  saveUsers,
  saveRoles,
  saveUsageById,
  saveUsage,
  saveUsageByUserId,
  clearUsageDataByUser,
  saveUserDetailById,
  clearUserDataById,
  clearUserManagement,
} = userSlice.actions;

export default userSlice.reducer;

export const selectAllUsers = (state: RootState): IUser[] =>
  state.userManagement.users;

export const selectUserDetailById = (state: RootState): IUser =>
  state.userManagement.userDetailsById;

export const selectAllRoles = (state: RootState): IRole[] =>
  state.userManagement.roles;

export const selectGeneralUsage = (state: RootState): IUsage[] =>
  state.userManagement.usage;

export const selectUsageByUserId = (state: RootState): IUsage[] =>
  state.userManagement.usageByUserId;

export const selectUsageById = (state: RootState): IUsage =>
  state.userManagement.usageById;
