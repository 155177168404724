import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import PungoButton from "../../pungo-ui/PungoButton";
import Alas from "../../assets/PungoIcons/Alas.svg";

import styles from "./index.module.scss";


interface ErrorPageProps {
  type: "error" | "unauthorized";
}

const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const { type } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isErrorPage = type === "error";

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {isErrorPage ? t("errorPageTitle") : t("missingPermissionsTitle")}
        </div>
        <div className={styles.body}>
          <Trans>
            {isErrorPage ? t("errorPageBody") : t("missingPermissionsBody")}
          </Trans>
        </div>
        <div className={styles.button}>
          <PungoButton
            label={`${t("errorPageButton")}`}
            onClick={() => navigate("/")}
            color="tertiary"
          />
        </div>
      </div>
      <img className={styles.gif} src={Alas} alt="Text animation" />
    </div>
  );
};

export default ErrorPage;
