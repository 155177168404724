import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";

import PungoDemoAccordion from "pungo-ui/PungoDemoAccordion";
import PungoTable from "pungo-ui/PungoTable";
import { selectProResults } from "store/slices/plan";
import SelectableTabs from "../../common/SelectableTabs";
import PungoDemoResultsCard from "../../../../../../pungo-ui/PungoDemoResultsCard/index";

import styles from "./index.module.scss";
import colors from "../../../../../../styles/export.module.scss";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const Corrosion: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isSpanishSelected = i18n.language === "es";
  const proPlanResults = useSelector(selectProResults);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const labelsForConsolidatedResultsTable = [
    t("proPlan.tableLabels.consolidatedResults.well"),
    t("proPlan.tableLabels.consolidatedResults.corrosionSpeedHead"),
    t("proPlan.tableLabels.consolidatedResults.corrosionRiskHead"),
    t("proPlan.tableLabels.consolidatedResults.corrosionSpeedBottom"),
    t("proPlan.tableLabels.consolidatedResults.corrosionRiskBottom"),
    t("proPlan.tableLabels.consolidatedResults.corrosionSpeedMax"),
  ];

  const translateResults = (level: string) => {
    switch (level) {
      case "low":
        return isSpanishSelected ? "Bajo" : "Low";
      case "medium":
        return isSpanishSelected ? "Medio" : "Medium";
      case "high":
        return isSpanishSelected ? "Alto" : "High";
      case "very_high":
        return isSpanishSelected ? "Muy alto" : "Very high";
      case "Sobre-dosificado":
        return isSpanishSelected ? level : "Over-dosed";
      case "Sub-dosificado":
        return isSpanishSelected ? level : "Under-dosed";
      default:
        return "";
    }
  };

  const getColorForResults = (level: string) => {
    switch (level) {
      case "low":
        return colors.green2;
      case "medium":
        return colors.yellow1;
      case "high":
        return colors.red1;
      case "very_high":
        return colors.red2;
      case "Sobre-dosificado":
        return colors.red3;
      case "Sub-dosificado":
        return colors.red1;
      default:
        return "";
    }
  };

  const corrosionData = {
    labels: proPlanResults[selectedIndex].corrosionResult.depth.map((value) =>
      value.toFixed(2)
    ),

    datasets: [
      {
        data: proPlanResults[selectedIndex].corrosionResult.corrosion,
        borderWidth: 1,
        borderColor: colors.red1,
        tension: 0.4,
        pointStyle: "circle",
        pointBackgroundColor: colors.red1,
        backgroundColor: colors.red1,
        pointBorderColor: colors.red1,
        pointRadius: 1.5,
      },
    ],
  };

  const y: "x" | "y" = "y";

  const lineChartConfigForCorrosion = {
    indexAxis: y,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => {
            return proPlanResults[selectedIndex].wellName;
          },
          label: (ctx: any) => {
            return [
              `${t("DemoPage.ResultsSection.corrosionSpeedAxis")} = ${
                proPlanResults[selectedIndex].corrosionResult.corrosion[
                  ctx.dataIndex
                ].toFixed(2) || 0
              }`,
              `${t("DemoPage.ResultsSection.depthAxis")} = ${
                proPlanResults[selectedIndex].corrosionResult.depth[
                  ctx.dataIndex
                ].toFixed(2) || 0
              }`,
              `${t("DemoPage.ResultsSection.pressionAxis")} = ${
                proPlanResults[selectedIndex].corrosionResult.pressure[
                  ctx.dataIndex
                ].toFixed(2) || 0
              }`,
              `${t("DemoPage.ResultsSection.temperatureAxis")} = ${
                proPlanResults[selectedIndex].corrosionResult.temperature[
                  ctx.dataIndex
                ].toFixed(2) || 0
              }`,
              `${t("DemoPage.ResultsSection.corrosionRisk")} = ${
                translateResults(
                  proPlanResults[selectedIndex].corrosionResult.corrosionRisk[
                    ctx.dataIndex
                  ]
                ) || ""
              }`,
            ];
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        display: true,
        position: "top" as const,
        title: {
          display: true,
          color: colors.gray1,
          text: t("DemoPage.ResultsSection.corrosionSpeedAxis"),
        },
        grid: {
          color: (ctx: any) => {
            if (ctx.index === 0) {
              return `${colors.gray1}`;
            } else {
              return "transparent";
            }
          },
        },
        ticks: {
          color: colors.gray1,
        },
      },

      y: {
        display: true,
        title: {
          display: true,
          color: colors.gray1,
          text: t("DemoPage.ResultsSection.depthAxis"),
        },
        grid: {
          color: (ctx: any) => {
            if (ctx.index === 0) {
              return `${colors.gray1}`;
            } else {
              return "transparent";
            }
          },
        },
        ticks: {
          color: colors.gray1,
        },
      },
    },
  };

  const getTableContent = () => (
    <div className={styles.container}>
      <div className={styles.table}>
        <PungoTable
          values={proPlanResults?.map(({ wellName, corrosionResult }) => {
            return [
              wellName,
              corrosionResult.corrosionHead,
              translateResults(corrosionResult.corrosionRiskHead),
              corrosionResult.corrosionDepth,
              translateResults(corrosionResult.corrosionRiskDepth),
              corrosionResult.corrosionMax,
            ];
          })}
          labels={labelsForConsolidatedResultsTable}
          height={300}
        />
      </div>
      <div className={styles.tabs}>
        <SelectableTabs
          labels={proPlanResults?.map(({ wellName }) => wellName)}
          onSelect={setSelectedIndex}
          selectedIndex={selectedIndex}
        />
      </div>
      <div className={styles.section}>
        <div className={styles.label}>
          {t("DemoPage.ResultsSection.corrosionSpeed")}
        </div>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.headCorrosion") as string}
            result={`${proPlanResults[selectedIndex].corrosionResult.corrosionHead} MPY`}
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.bottomCorrosion") as string}
            result={`${proPlanResults[selectedIndex].corrosionResult.corrosionDepth} MPY`}
            color={colors.blue4}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.maxCorrosion") as string}
            result={`${proPlanResults[selectedIndex].corrosionResult.corrosionMax} MPY`}
            color={colors.blue4}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>
          {t("DemoPage.ResultsSection.corrosionRisk")}
        </div>
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.headRisk") as string}
            result={translateResults(
              proPlanResults[selectedIndex].corrosionResult.corrosionRiskHead
            )}
            color={getColorForResults(
              proPlanResults[selectedIndex].corrosionResult.corrosionRiskHead
            )}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.bottomRisk") as string}
            result={translateResults(
              proPlanResults[selectedIndex].corrosionResult.corrosionRiskDepth
            )}
            color={getColorForResults(
              proPlanResults[selectedIndex].corrosionResult.corrosionRiskDepth
            )}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.ResultsSection.maxRisk") as string}
            result={translateResults(
              proPlanResults[selectedIndex].corrosionResult.corrosionRiskMax
            )}
            color={getColorForResults(
              proPlanResults[selectedIndex].corrosionResult.corrosionRiskMax
            )}
          />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <div className={styles.label}>
          {t("DemoPage.ResultsSection.corrosionProfile")}
        </div>
        <div className={styles.chartContainer}>
          <Line data={corrosionData} options={lineChartConfigForCorrosion} />
        </div>
      </div>
    </div>
  );

  return (
    <PungoDemoAccordion
      title={t("DemoPage.ResultsSection.corrosion")}
      content={getTableContent()}
    />
  );
};

export default Corrosion;
