import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import { AppDispatch } from "../index";
import { displaySnackbarAPISuccess, getSnackbarError } from "./utils";
import { v4 as uuidv4 } from "uuid";
import { addAPIcall, completeAPIcall } from "store/slices/api";
import { setVerifyingAccount } from "store/slices/account";

interface IRegisterAccount {
  name: string;
  companyName: string;
  email: string;
  password: string;
}
interface IVerifyAccount {
  username: string;
  code: string;
}
interface IChangePassword extends IVerifyAccount {
  password: string;
}
interface Iuser {
  username: string;
}

const accountURL = getBaseUrlForAPI("account");

export const registerAccount =
  (registerInfo: IRegisterAccount, handleRedirect?: () => void) =>
  (dispatch: AppDispatch): void => {
    const id = `registerAccount-${uuidv4()}`;
    dispatch(addAPIcall(id));
    axiosInstance
      .post(`${accountURL}/register`, registerInfo)
      .then(() => {
        dispatch(displaySnackbarAPISuccess("successMessage.register"));
        if (handleRedirect) {
          handleRedirect();
        }
      })
      .catch((error) => {
        getSnackbarError(error);
      })
      .finally(() => {
        dispatch(completeAPIcall(id));
      });
  };

export const forgotPassword =
  (user: Iuser) =>
  (dispatch: AppDispatch): void => {
    const id = `forgotPassword-${uuidv4()}`;

    axiosInstance
      .post(`${accountURL}/forgot-password`, user)
      .then(() => {
        dispatch(displaySnackbarAPISuccess("successMessage.forgotPassword"));
      })
      .catch((error) => {
        getSnackbarError(error);
      })
      .finally(() => {
        dispatch(completeAPIcall(id));
      });
  };

export const changePassword =
  (data: IChangePassword, handleRedirect?: () => void) =>
  (dispatch: AppDispatch): void => {
    const id = `changePassword-${uuidv4()}`;

    axiosInstance
      .post(`${accountURL}/password-change`, data)
      .then(() => {
        dispatch(displaySnackbarAPISuccess("successMessage.changePassword"));
        if (handleRedirect) {
          handleRedirect();
        }
      })
      .catch((error) => {
        getSnackbarError(error);
      })
      .finally(() => {
        dispatch(completeAPIcall(id));
      });
  };

export const verifyAccount =
  (data: IVerifyAccount) =>
  (dispatch: AppDispatch): void => {
    const id = `verifyAccount-${uuidv4()}`;
    dispatch(setVerifyingAccount(true));
    axiosInstance
      .post(`${accountURL}/verify`, data)
      .then(() => {
        dispatch(displaySnackbarAPISuccess("successMessage.verifyAccount"));
      })
      .catch((error) => {
        getSnackbarError(error);
      })
      .finally(() => {
        dispatch(completeAPIcall(id));
        dispatch(setVerifyingAccount(false));
      });
  };

