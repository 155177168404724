import React from "react";
import classnames from "classnames";

import styles from "./index.module.scss";

interface PungoNonClickableProps {
  children: React.ReactNode;
  classNames?: string;
}

const PungoNonClickable: React.FC<PungoNonClickableProps> = (props) => {
  const { children, classNames } = props;

  const getClassNames = () => classnames(classNames, styles.container);

  return <div className={getClassNames()}>{children}</div>;
};

export default PungoNonClickable;
