import React from "react";
import Modal from "@mui/material/Modal";

import styles from "../PungoModal/index.module.scss";

interface PungoNotificationModalProps {
  open: boolean;
  title?: string;
  children?: React.ReactNode;
}

const PungoNotificationModal: React.FC<PungoNotificationModalProps> = (
  props
) => {
  const { open, children, title } = props;

  return (
    <div className={styles.container}>
      <Modal open={open} disableAutoFocus>
        <div className={styles.modal}>
          {title && <div className={styles.header}>{title}</div>}
          <div>{children}</div>
        </div>
      </Modal>
    </div>
  );
};

export default PungoNotificationModal;
