import { saveSurvey, setAnswerLoader } from "store/slices/survey";
import { AppDispatch } from "../index";
import { v4 as uuidv4 } from "uuid";
import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import { displaySnackbarAPISuccess, getSnackbarError } from "./utils";
import { addAPIcall, completeAPIcall } from "store/slices/api";
import moment, { Moment } from "moment";
import { store } from "../../store";
import { clerUserSession, refresh } from "./authActions";

const surveyURL = getBaseUrlForAPI("survey");

interface ISurveyAnswer {
  questionNumber: number;
  choicesNumbers: number[];
}

export const getSurvey =
  () =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `getSurvey-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${surveyURL}`)
        .then(({ data }) => {
          dispatch(saveSurvey(data));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const sendAnswer =
  (answer: ISurveyAnswer, handleOnSend?: () => void) =>
  (dispatch: AppDispatch): void => {
    dispatch(setAnswerLoader(true));

    const localRequest = () => {
      const id = `sendAnswer-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${surveyURL}/answer`, answer)
        .then(() => {
          dispatch(
            displaySnackbarAPISuccess("successMessage.surveyAnswerSended")
          );
          if (handleOnSend) {
            handleOnSend();
          }
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
          dispatch(setAnswerLoader(false));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };
