import React from "react";
import styles from "./index.module.scss";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface PungoAccordionProps {
  title: string;
  content?: React.ReactNode;
}

const PungoAccordion: React.FC<PungoAccordionProps> = (props) => {
  const { title, content } = props;
  const [expanded, setExpanded] = React.useState<string | boolean>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className={styles.mainContainer}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        elevation={0}
        sx={{
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ content: styles.innerContainer }}
        >
          <div className={styles.titleContainer}>
            <div className={styles.accordionTitle}>{title}</div>
            <div className={styles.icon}>
              {expanded ? (
                <ExpandLessIcon fontSize="large" />
              ) : (
                <ExpandMoreIcon fontSize="large" />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={styles.content}>
          {content}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PungoAccordion;
