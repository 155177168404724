import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useAppDispatch } from "app/hooks";

import PungoButton from "pungo-ui/PungoButton";
import { IProPlanModalMessage, clearPlanModal } from "store/slices/planModal";

const AstroDialog: React.FC<IProPlanModalMessage> = (props) => {
  const { text } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleOnClose = () => dispatch(clearPlanModal());

  return (
    <Dialog open={!!text} onClose={handleOnClose}>
      <DialogTitle>
        {t("DemoPage.InstructionsSection.planModalTitle")}
      </DialogTitle>
      <DialogContent>
        <Trans>{text}</Trans>
      </DialogContent>
      <DialogActions>
        <PungoButton label="OK" onClick={handleOnClose} color="secondary" />
      </DialogActions>
    </Dialog>
  );
};

export default AstroDialog;
