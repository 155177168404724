import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

import PungoInput from "../../pungo-ui/PungoInput";
import PungoButton from "../../pungo-ui/PungoButton";
import { changePassword } from "store/actions/accountActions";
import { useAppDispatch } from "app/hooks";

import CommonTemplate from "components/common/CommonTemplate";

const RecoveryAccountPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { search } = location;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const isConfirmPasswordCorrect = password === confirmPassword;

  const urlParams = new URLSearchParams(search);

  const email = urlParams?.get("username") || "";
  const code = urlParams?.get("code") || "";

  const redirectAfterSuccessCall = () => navigate("/login");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOnSend = () => {
    dispatch(
      changePassword(
        { username: email, code, password },
        redirectAfterSuccessCall
      )
    );
  };

  return (
    <CommonTemplate title={`${t("recoveryPage.header")}`} height={300}>
      <PungoInput
        name={`${t("accountPage.passwordLabel")}`}
        type="password"
        value={password}
        onChange={setPassword}
      />
      <PungoInput
        name={`${t("accountPage.confirmPassword")}`}
        type="password"
        value={confirmPassword}
        onChange={setConfirmPassword}
        helperText={
          !isConfirmPasswordCorrect
            ? `${t("accountPage.mismatchedPasswords")}`
            : undefined
        }
      />
      <PungoButton
        label={`${t("recoveryPage.sendLabel")}`}
        onClick={handleOnSend}
        color="secondary"
        disabled={!isConfirmPasswordCorrect || !password}
      />
    </CommonTemplate>
  );
};

export default RecoveryAccountPage;
