import React, { useState } from "react";

import { Trans, useTranslation } from "react-i18next";

import { DataGrid } from "@mui/x-data-grid";
import { Add, Delete, Edit, BarChart, ContactPage } from "@mui/icons-material";
import PungoClickable from "pungo-ui/PungoClickable";
import PungoModal from "pungo-ui/PungoModal";

import classnames from "classnames";
import styles from "./index.module.scss";

interface AdminTableProps {
  columns: any[];
  data: any[];
  handleOnEdit?: (shouldShow: boolean) => void;
  handleOnUsage?: (shouldShow: boolean) => void;
  handleOnUser?: (shouldShow: boolean) => void;
  handleOnFetchRol?: () => void;
  handleOnDelete?: () => void;
  handleOnSelection?: (id: number | undefined) => void;
  actions?: ("stats" | "add" | "delete" | "edit" | "user")[];
  classNames?: string;
  hasTopMargin?: boolean;
}

const AdminUsersTable: React.FC<AdminTableProps> = (props) => {
  const {
    columns,
    data,
    handleOnEdit,
    handleOnSelection,
    handleOnDelete,
    handleOnUser,
    handleOnUsage,
    handleOnFetchRol,
    classNames,
    actions,
    hasTopMargin = true,
  } = props;
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isInfoTable =
    (actions?.length === 2 &&
      actions?.includes("stats") &&
      actions?.includes("user")) ||
    (actions?.length === 1 && actions?.includes("stats"));

  const newColumns = [
    {
      field: "actions",
      headerName: "Actions",
      fixed: true,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }: any) => {
        const areActionsDisabled = row.status !== "ACTIVE";
        return (
          <div className={styles.actions}>
            {actions?.includes("stats") && (
              <PungoClickable
                onClick={() => {
                  if (handleOnUsage) {
                    handleOnUsage(true);
                  }
                }}
                classNames={classnames(styles.edit, {
                  [styles.disabled]: areActionsDisabled && !isInfoTable,
                })}
              >
                <BarChart />
              </PungoClickable>
            )}
            {actions?.includes("edit") && (
              <PungoClickable
                onClick={() => {
                  if (handleOnEdit) {
                    handleOnEdit(true);
                    if (handleOnFetchRol) {
                      handleOnFetchRol();
                    }
                  }
                }}
                classNames={classnames(styles.edit, {
                  [styles.disabled]: areActionsDisabled && !isInfoTable,
                })}
              >
                <Edit />
              </PungoClickable>
            )}
            {actions?.includes("delete") && (
              <PungoClickable
                onClick={() => setShowDeleteModal(true)}
                classNames={classnames(styles.delete, {
                  [styles.disabled]: areActionsDisabled && !isInfoTable,
                })}
              >
                <Delete />
              </PungoClickable>
            )}
            {actions?.includes("user") && (
              <PungoClickable
                onClick={() => {
                  if (handleOnUser) {
                    handleOnUser(true);
                  }
                }}
                classNames={classnames(styles.edit, {
                  [styles.disabled]: areActionsDisabled && !isInfoTable,
                })}
              >
                <ContactPage />
              </PungoClickable>
            )}
          </div>
        );
      },
    },
    ...columns,
  ];

  const handleOnClose = () => {
    setShowDeleteModal(false);
    if (handleOnSelection) {
      handleOnSelection(undefined);
    }
  };

  return (
    <div className={classnames(classNames, styles.grid)}>
      {hasTopMargin && (
        <div className={styles.addUser}>
          {actions?.includes("add") && (
            <PungoClickable
              classNames={styles.addIcon}
              onClick={() => {
                if (handleOnEdit) {
                  handleOnEdit(true);
                  if (handleOnFetchRol) {
                    handleOnFetchRol();
                  }
                }
              }}
            >
              <Add />
            </PungoClickable>
          )}
        </div>
      )}
      <DataGrid
        rows={data}
        className={styles.table}
        columns={actions?.length ? newColumns : columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) => row.id}
        disableColumnMenu
        onSelectionModelChange={(row) => {
          const rowStatus = data.find(({ id }) => id === +row[0]).status;
          if ((rowStatus && rowStatus === "ACTIVE") || isInfoTable) {
            if (handleOnSelection) {
              handleOnSelection(+row[0]);
            }
          }
        }}
      />
      <PungoModal
        handleClose={handleOnClose}
        classNames={styles.deleteModal}
        open={showDeleteModal}
        primaryAction={{
          label: t("admin.deleteLabel"),
          onClick: handleOnDelete,
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnClose,
        }}
      >
        <div className={styles.deleteModalChild}>
          <Trans>{t("admin.deleteElementLabel")}</Trans>
        </div>
      </PungoModal>
    </div>
  );
};

export default AdminUsersTable;
