import {
  loginInUser,
  logOutUser,
  saveLoginEmail,
  saveUserName,
  saveAccountStatus,
  saveRole,
  saveSurveyStatus,
  cleanUser,
  ILoginInfo,
  setLoginInfo,
} from "store/slices/auth";
import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import { AppDispatch } from "../index";
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import {
  displaySnackbarAPIError,
  displaySnackbarAPISuccess,
  getSnackbarError,
} from "./utils";
import { addAPIcall, completeAPIcall, clearAPIcalls } from "store/slices/api";
import moment, { Moment } from "moment";
import { clearSurvey, setAnswerLoader } from "store/slices/survey";
import { store } from "../../store";
import { clearQuestions } from "store/slices/question";
import { clearUserManagement } from "store/slices/user";
import {
  clearProResults,
  clearResults,
  saveSelectedPlan,
  setFetchedMockedDataStatus,
} from "store/slices/plan";
import { clearProfileManagement } from "store/slices/profile";

interface ILoginSession {
  username: string;
  password: string;
}
interface IPasswordChange {
  oldPassword: string;
  newPassword: string;
}
export interface ILogoutSession {
  id_token: string;
}
export interface IRefreshSession {
  refresh_token: string;
}
export interface ITokenInfo {
  email: string;
  email_verified: boolean;
  name: string;
  realm_access: { roles: string[] };
  survey_completed: boolean;
}

const sessionURL = getBaseUrlForAPI("session");
const passwordChangeURL = getBaseUrlForAPI("passwordChange");

export const logInSession =
  (userInfo: ILoginSession, handleRedirect?: () => void) =>
  (dispatch: AppDispatch): void => {
    const id = `logInSession-${uuidv4()}`;
    dispatch(addAPIcall(id));
    axiosInstance
      .post(`${sessionURL}/login`, userInfo)
      .then(({ data }) => {
        dispatch(loginInUser(data));
        const extraInfo: ITokenInfo = jwt_decode(data.access_token);
        dispatch(saveLoginEmail(extraInfo?.email));
        dispatch(saveUserName(extraInfo?.name));
        dispatch(saveAccountStatus(extraInfo?.email_verified));
        dispatch(saveSurveyStatus(extraInfo?.survey_completed));
        dispatch(saveRole(extraInfo?.realm_access?.roles));
        dispatch(displaySnackbarAPISuccess("successMessage.login"));

        let currentTime: moment.Moment = moment();
        var loginInfo: ILoginInfo = {
          loginTime: currentTime.format(),
          tokenExpireTime: currentTime
            .add(data.expires_in - 20, "seconds")
            .format(), //token expire time less 20 seconds as threashold
          sessionExpireTime: currentTime
            .add(data.refresh_expires_in - 20, "seconds")
            .format(), //token expire time less 20 seconds threashold
        };
        dispatch(setLoginInfo(loginInfo));
      })
      .then(() => {
        dispatch(clearResults());
        if (handleRedirect) {
          handleRedirect();
        }
      })
      .catch((error) => {
        getSnackbarError(error);
      })
      .finally(() => {
        dispatch(completeAPIcall(id));
      });
  };

export const logOutSession =
  (id_token: ILogoutSession) =>
  (dispatch: AppDispatch): void => {
    const id = `logOutSession-${uuidv4()}`;
    dispatch(addAPIcall(id));
    axiosInstance
      .post(`${sessionURL}/logout`, id_token)
      .then(() => {
        dispatch(clerUserSession());
      })
      .catch(() => {
        dispatch(clerUserSession());
      })
      .finally(() => {
        dispatch(completeAPIcall(id));
      });
  };

export const clerUserSession =
  () =>
  (dispatch: AppDispatch): void => {
    dispatch(setAnswerLoader(false));
    dispatch(logOutUser());
    dispatch(clearQuestions());
    dispatch(clearUserManagement());
    dispatch(displaySnackbarAPIError("expiredSession"));
    dispatch(clearSurvey());
    dispatch(clearResults());
    dispatch(clearProfileManagement());
    dispatch(clearAPIcalls());
    dispatch(clearProResults());
    dispatch(saveSelectedPlan("free"));
    dispatch(setFetchedMockedDataStatus(false));
  };

export const refresh =
  (refreshToken: IRefreshSession, originalRequest?: () => void) =>
  (dispatch: AppDispatch): void => {
    const id = `refreshSession-${uuidv4()}`;
    dispatch(cleanUser());
    dispatch(addAPIcall(id));
    axiosInstance
      .post(`${sessionURL}/refresh`, refreshToken)
      .then(({ data }) => {
        dispatch(loginInUser(data));
        const extraInfo: ITokenInfo = jwt_decode(data.access_token);
        dispatch(saveLoginEmail(extraInfo?.email));
        dispatch(saveUserName(extraInfo?.name));
        dispatch(saveAccountStatus(extraInfo?.email_verified));
        dispatch(saveSurveyStatus(extraInfo?.survey_completed));
        dispatch(saveRole(extraInfo?.realm_access?.roles));

        let currentTime: moment.Moment = moment();
        var loginInfo: ILoginInfo = {
          loginTime: currentTime.format(),
          tokenExpireTime: currentTime
            .add(data.expires_in - 20, "seconds")
            .format(), //token expire time less 20 seconds as threashold
          sessionExpireTime: currentTime
            .add(data.refresh_expires_in - 20, "seconds")
            .format(), //token expire time less 20 seconds threashold
        };
        dispatch(setLoginInfo(loginInfo));

        if (originalRequest) {
          originalRequest();
        }
      })
      .catch((error) => {
        getSnackbarError(error);
        dispatch(clerUserSession());
      })
      .finally(() => {
        dispatch(completeAPIcall(id));
      });
  };

export const changePasswordFromSession =
  (passwords: IPasswordChange) =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `changePasswordFromSession-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${passwordChangeURL}`, passwords)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.changePassword"));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime: Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };
