import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectSelectedPlan } from "store/slices/plan";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";

import styles from "./index.module.scss";

const PlanInformationSection: React.FC = () => {
  const { t } = useTranslation();
  const selectedPlan = useSelector(selectSelectedPlan);

  const getFreePlanInformationText = () => (
    <div className={styles.text}>
      <div className={styles.note}>
        <Trans>{t("DemoPage.PlanInformationSection.note1")}</Trans>
      </div>
      <div className={styles.division}></div>
      <div className={styles.note}>
        <Trans>{t("DemoPage.PlanInformationSection.note2")}</Trans>
      </div>
    </div>
  );

  return (
    <PungoSectionCard title={""}>
      <div className={styles.container}>
        {selectedPlan === "free" ? getFreePlanInformationText() : null}
      </div>
    </PungoSectionCard>
  );
};

export default PlanInformationSection;
