import React from "react";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUserRole } from "store/slices/auth";

import star from "../../../assets/PungoIcons/Star.svg";

import styles from "./index.module.scss";

const ProBadge: React.FC = () => {
  const { t } = useTranslation();
  const userRoles = useSelector(selectUserRole);
  const isFreeUser = userRoles?.includes("free_plan");

  return (
    <Tooltip
      title={
        <div style={{ fontSize: "20px", textAlign: "center" }}>
          {isFreeUser ? t("proPlan.badge") : t("proPlan.proBadge")}
        </div>
      }
    >
      <div className={styles.container}>
        <img src={star} alt="" className={styles.star} />
        PRO
      </div>
    </Tooltip>
  );
};

export default ProBadge;
