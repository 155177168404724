import React, { useEffect, useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import {
  clearUsageDataByUser,
  selectAllRoles,
  selectAllUsers,
  selectUsageById,
  selectUsageByUserId,
} from "store/slices/user";
import { columnsForUsageTable, columnsForUserTable } from "../tableData";
import { useAppDispatch } from "app/hooks";
import { useTranslation } from "react-i18next";

import AdminTable from "../common/AdminTable";
import {
  IPaymentTypes,
  IUsageTypes,
  createUser,
  deleteUsers,
  editUser,
  fetchUsageById,
  fetchUsageByUserId,
  getRoles,
  getUsers,
} from "store/actions/userActions";
import PungoModal from "pungo-ui/PungoModal";
import PungoInput from "pungo-ui/PungoInput";
import { isEmail } from "components/utils/validations";
import { FormControlLabel, Switch } from "@mui/material";

import styles from "./index.module.scss";

const AdminUsersTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const usersData = useSelector(selectAllUsers);
  const rolesList = useSelector(selectAllRoles);
  const usageByUserData = useSelector(selectUsageByUserId);
  const detailedData = useSelector(selectUsageById);

  const [selectedUserId, setSelectedUserId] = useState<number | undefined>();
  const [selectedUsageId, setSelectedUsageId] = useState<number | undefined>();
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [usageType, setUsageType] = useState<IUsageTypes>("");
  const [paymentType, setPaymentType] = useState<IPaymentTypes>("");
  const [remainingUsages, setRemainingUsages] = useState(0);
  const [endDate, setEndDate] = useState("");
  const [emailVerificationCode, setEmailVerificationCode] = useState("");
  const [passwordChangeCode, setPasswordChangeCode] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [isSurveyCompleted, setIsSurveyCompleted] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [showUsageModal, setShowUsageModal] = useState(false);
  const [showDetailedUsageModal, setShowDetailedUsageModal] = useState(false);

  const isProUser = role === "pro_plan";
  const isNumberUsage = usageType === "NUMBER_OF_USAGES";
  const isEndDateUsage = usageType === "END_TIME";

  const userToEdit = usersData?.find(({ id }) => id === selectedUserId);

  useEffect(() => {
    dispatch(getUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setName(userToEdit?.name || "");
    setCompany(userToEdit?.companyName || "");
    setEmail(userToEdit?.email || "");
    setRole(userToEdit?.role || "");
    setEmailVerificationCode(userToEdit?.emailVerificationCode || "");
    setPasswordChangeCode(userToEdit?.passwordChangeCode || "");
    setIsEmailVerified(userToEdit?.emailVerified || false);
    setIsSurveyCompleted(userToEdit?.surveyCompleted || false);
    setUsageType(userToEdit?.planUsageType || "");
    setPaymentType(userToEdit?.planPaymentType || "");
    setRemainingUsages(userToEdit?.planRemainingUsages || 0);
    setEndDate(userToEdit?.planEndDate || "");
  }, [userToEdit]);

  userToEdit?.planEndDate ? new Date(userToEdit?.planEndDate) : new Date();

  useEffect(() => {
    if (selectedUserId) {
      dispatch(fetchUsageByUserId(selectedUserId));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUsageModal]);

  useEffect(() => {
    if (selectedUsageId) {
      dispatch(fetchUsageById(selectedUsageId));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDetailedUsageModal]);

  const handleOnDeleteConfirm = () => {
    if (selectedUserId) {
      dispatch(deleteUsers(selectedUserId));
    }
  };

  useEffect(() => {
    if (email.length) {
      setTimeout(() => {
        if (isEmail(email)) {
          setIsValidEmail(true);
        } else {
          setIsValidEmail(false);
        }
      }, 300);
    }
  }, [email]);

  const clearForm = () => {
    setSelectedUserId(undefined);
    setName("");
    setCompany("");
    setEmail("");
    setRole("");
    setPassword("");
    setEmailVerificationCode("");
    setPasswordChangeCode("");
    setIsEmailVerified(false);
    setIsSurveyCompleted(false);
    setUsageType("");
    setPaymentType("");
    setRemainingUsages(0);
    setEndDate("");
  };

  const handleOnClose = () => {
    setSelectedUserId(undefined);
    setShowModal(false);
    clearForm();
  };

  const handleOnSave = () => {
    const userPayload = {
      username: email,
      name: name,
      email: email,
      ...(password && { password }),
      role,
      companyName: company,
      emailVerified: isEmailVerified,
      surveyCompleted: isSurveyCompleted,
      ...(emailVerificationCode && { emailVerificationCode }),
      ...(passwordChangeCode && { passwordChangeCode }),
      planUsageType: usageType || null,
      planPaymentType: paymentType || null,
      planEndDate: endDate,
      planRemainingUsages: remainingUsages,
    };

    if (selectedUserId) {
      dispatch(editUser(selectedUserId, userPayload));
      clearForm();
    } else {
      dispatch(createUser(userPayload));
      clearForm();
    }
  };

  const handleOnCloseUsageModal = () => {
    dispatch(clearUsageDataByUser());
    setShowUsageModal(false);
    setSelectedUserId(undefined);
  };

  const handleOnCloseDetailedUsageModal = () => {
    setShowDetailedUsageModal(false);
    setSelectedUsageId(undefined);
  };

  const handleChangeVerifiedMail = (event: ChangeEvent<HTMLInputElement>) => {
    setIsEmailVerified(event.target.checked);
  };

  const handleChangeSurveyCompleted = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setIsSurveyCompleted(event.target.checked);
  };

  const paymentTypes = [
    { value: "MANUAL", label: "Manual" },
    {
      value: "CREDIT_CARD",
      label: ` 
  ${t("user.creditCard")}`,
    },
  ];

  const usageTypes = [
    {
      value: "END_TIME",
      label: ` 
  ${t("user.endTime")}`,
    },
    {
      value: "NUMBER_OF_USAGES",
      label: ` 
  ${t("user.usageNumber")}`,
    },
  ];

  const isContinueButtonDisabled =
    !name ||
    !email ||
    !role ||
    !company ||
    !isValidEmail ||
    (selectedUserId ? false : !password);

  const getCreateUserModal = () => {
    return (
      <PungoModal
        open={showModal}
        classNames={styles.modalParentForCreate}
        title={
          selectedUserId
            ? `${t("admin.editTitle")}`
            : `${t("admin.createTitle")}`
        }
        handleClose={handleOnClose}
        primaryAction={{
          label: selectedUserId ? t("admin.editLabel") : t("admin.createLabel"),
          onClick: handleOnSave,
          disabled: isContinueButtonDisabled,
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnClose,
        }}
      >
        <div className={styles.modal}>
          <PungoInput
            name={`${t("user.nameLabel")}`}
            value={name}
            onChange={setName}
          />
          <PungoInput
            name={`${t("user.companyLabel")}`}
            value={company}
            onChange={setCompany}
          />
          <PungoInput
            name={`${t("user.emailLabel")}`}
            value={email}
            onChange={setEmail}
            disabled={selectedUserId ? true : false}
            helperText={
              isValidEmail ? undefined : `${t("accountPage.invalidEmail")}`
            }
          />
          <PungoInput
            name={`${t("user.passwordLabel")}`}
            value={password}
            onChange={setPassword}
            type="password"
          />
          <PungoInput
            name={`${t("user.emailVerificationCodeLabel")}`}
            value={emailVerificationCode}
            onChange={setEmailVerificationCode}
          />
          <PungoInput
            name={`${t("user.passwordChangeCodeLabel")}`}
            value={passwordChangeCode}
            onChange={setPasswordChangeCode}
          />
          <PungoInput
            name={`${t("user.roleLabel")}`}
            value={role}
            onChange={setRole}
            type="select"
            options={rolesList.map(({ role }) => {
              return { value: role, label: role };
            })}
          />
          {isProUser && (
            <PungoInput
              name={`${t("user.paymentType")}`}
              value={paymentType || ""}
              onChange={setPaymentType}
              type="select"
              options={paymentTypes.map((type) => type)}
            />
          )}
          {isProUser && (
            <PungoInput
              name={`${t("user.usageType")}`}
              value={usageType || ""}
              onChange={setUsageType}
              type="select"
              options={usageTypes.map((type) => type)}
            />
          )}
          {isProUser && isNumberUsage && (
            <PungoInput
              name={`${t("user.remainingUsages")}`}
              value={remainingUsages}
              onChange={setRemainingUsages}
              type="number"
              options={usageTypes.map((type) => type)}
            />
          )}
          {isProUser && isEndDateUsage && (
            <PungoInput
              name={`${t("user.usageEndDate")}`}
              value={endDate}
              type="date"
              onChange={(newValue) => setEndDate(newValue)}
            />
          )}
          <div className={styles.centeredItem}>
            <FormControlLabel
              control={
                <Switch
                  checked={isEmailVerified}
                  onChange={handleChangeVerifiedMail}
                />
              }
              label={t("user.verifiedEmailLabel")}
              labelPlacement="start"
            />
          </div>
          <div className={styles.centeredItem}>
            <FormControlLabel
              control={
                <Switch
                  checked={isSurveyCompleted}
                  onChange={handleChangeSurveyCompleted}
                />
              }
              label={t("user.completedSurveyLabel")}
              labelPlacement="start"
            />
          </div>
        </div>
      </PungoModal>
    );
  };

  return (
    <div>
      <AdminTable
        columns={columnsForUserTable}
        data={usersData || []}
        handleOnEdit={setShowModal}
        handleOnDelete={handleOnDeleteConfirm}
        handleOnFetchRol={() => dispatch(getRoles())}
        handleOnSelection={setSelectedUserId}
        handleOnUsage={setShowUsageModal}
        actions={["stats", "add", "delete", "edit"]}
      />
      {getCreateUserModal()}
      <PungoModal
        open={showUsageModal}
        title={`${t("admin.usageModal")}`}
        handleClose={handleOnCloseUsageModal}
        classNames={styles.modalParentForUsage}
        primaryAction={{
          label: "Ok",
          onClick: handleOnCloseUsageModal,
        }}
      >
        <AdminTable
          columns={columnsForUsageTable}
          data={usageByUserData || []}
          handleOnUsage={setShowDetailedUsageModal}
          handleOnSelection={setSelectedUsageId}
          actions={["stats"]}
          hasTopMargin={false}
          classNames={styles.usageModal}
        />
      </PungoModal>
      <PungoModal
        open={showDetailedUsageModal}
        title={`${t("admin.detailsLabel")}`}
        handleClose={handleOnCloseDetailedUsageModal}
        classNames={styles.detailsModal}
        primaryAction={{
          label: "Ok",
          onClick: handleOnCloseDetailedUsageModal,
        }}
      >
        <code className={styles.detailsModalChild}>
          <pre>{JSON.stringify(detailedData, null, 4)}</pre>
        </code>
      </PungoModal>
    </div>
  );
};

export default AdminUsersTable;
